.footer-container {
    color: white;
    background-color: #35412c;
    width: 100%;
    font-family: Poppins, sans-serif;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    font-size: 11px;
    height: 9%;
    letter-spacing: 1.5px;
    padding: 6px;
    align-items: center;
}

.logo-container{
    display: flex;
    justify-content: center;
    height: 80%;
    width: 100%;
}
.logo-container a{
    height: 100%;
    width: 100%;
}
.network-directory-logo-link{
width: 100%;
}
.footer-container .conatct-container .copyright-text{
    text-align: left;
}

.footer-container .conatct-container div span img{
    width: 9px;
    vertical-align: baseline;
}
.footer-container .copy-right-container {
    text-align: right;
    display: flex;
    flex-direction: column;
    margin:0px 20px;
    justify-content: space-evenly;
    align-self: flex-end;
    margin-top: 0.5%
}

.footer-container .copy-right-container div li small {
    font-size: 12px;
    display: flex;
}

.footer-container .copyright-txt .middle-content {
    display: flex;
    margin-bottom: 2%;
    font-weight: bold
}

.footer-container .copyright-txt {
    margin-top: 0.3%;
}

.footer-container .copyright-txt .middle-content {
    margin-bottom: 2%;
}

.network-directory-ctr{
    display: flex;
    height: 100%;
    width: 25%;
    justify-content: flex-end;
}
 .network-directory-logo-link{
    align-items: center;
    display: flex;
    justify-content: flex-end;
    margin-right: 10px;
    height: 85%;
   
}
.network-directory-image{
  
    height: 90%;
}
.network-directory-mobile-logo {
    height: 70%;
    cursor: pointer;
}
.footer-container .middle-content a {
    text-decoration: none;
    color: white;
}
.conatct-container{
    width: 25%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    height: 100%;
    margin-left: 1%;
}
.copyright-txt{
    width: 50%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
}
.middle-content{
    display: flex;
    justify-content: space-between;

}
.middlePortion{
    height: 100%;
    width: fit-content;
    display: flex;
    justify-content: center;
    flex-direction: column; 
    justify-content: space-evenly;
}
.ssLogoImage{
    height: 70%;
}
.copyright-text{
    display: flex;
    justify-content: center;
}
.version-divA{
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.aTag{
    display: flex;
    align-items: center;
}
.aTag-mobile-logo, .copyright-text-mobile{
    display: none;
}
.mobile-network-directory-logo-link{
    display: none;
}
.network-directory-mobile-logo{
    display: none;
}
.network-directory-logo-link{
    margin-top: 1%;
}
@media screen and (min-width: 920px) and (max-width: 1025px) {
    .copyright-txt{
        width: 50%;
    }
    .network-directory-ctr{
        width: 20%;
    }
    .network-directory-logo-link{
        height: 85%;
        width: 100%;
    }
    .network-directory-image{
        max-width: -webkit-fill-available;
    }
    .ssLogoImage{
        max-width: -webkit-fill-available;
    }
  }
  @media screen and (min-width: 800px) and (max-width: 920px) {
    .network-directory-ctr img{
        max-width: -webkit-fill-available;
    }
    .ssLogoImage{
      max-width: -webkit-fill-available;
    }
    .network-directory-image{
        height: 80%;
    }
  }

  @media screen and (min-width: 790px) and (max-width: 800px) {
    .network-directory-ctr img{
        width: 1;
        height: 1;
    }
    .ssLogoImage{
        height: 1;
        width: 1;
    }
  }

  @media screen and (min-width: 760px) and (max-width: 790px) {
    .network-directory-ctr{
        width: 20%;
    }
    .network-directory-ctr img{
        height: 85%;
        width: 95%;
    }
    .ssLogoImage{
        height: 90%;
        width: 100%;
    }
  }
  @media screen and (min-width: 530px) and (max-width: 760px) {
    .network-directory-ctr{
        width: 20%;
    }
    .network-directory-ctr img{
        width: 100%;
    }
    .ssLogoImage{
        width: 100%;
    }
    .footer-container .copyright-txt .middle-content{
        font-size: 9px;
    }
  }
 
  @media screen and (min-width: 360px) and (max-width: 530px){
   .copyright-txt{
        width: 60%;
   }
   .network-directory-ctr{
     width: 20%;
   }
   .conatct-container{
        width: 20%;
   }
    .aTag-mobile-logo{
        display: flex;
        align-items: center;
    }
    .network-directory-mobile-logo{
        display: flex;
        height: 52%;
        margin-top: 21%;
    }
    .copyright-text-mobile{
        display: flex;
        margin-top: 8%;
    }
    .mobile-network-directory-logo-link{
        display: flex;
    }
    .footer-container .copyright-txt .middle-content{
        font-size: 9px;
    }
    .footer-container .middlePortion{
        width: 100%;
        margin-left: 8%;
    }
    .version-divA {
        font-size: 9px;
        margin-top:2%;
    }
    .mobile-logo{
        margin-bottom: 2%;
    }

}
@media screen and (max-width: 530px){
    .network-directory-logo-link, .copyright-text :first-child ,  .aTag{
      display: none;
    }
}
@media screen and (min-width: 360px) and (max-width: 400px){
    .network-directory-mobile-logo {
        height: 65%;
        margin-top: 6%;
    }
}