.batch-import-section{
    height: auto;
    width: 35%;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Open-Sans,sans-serif;
    padding: 15px 15px 15px 50px;
    font-size: 15px;
}
.batch-import-title{
    width: 100%;
    font-weight: bold;
    margin-bottom: 15px;
    font-size: 18px;
}
.batch-import-section input[type="file"]{
    font-family: Open-Sans,sans-serif;
}
.batch-import-content-container{
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
}
.file-template-container{
    display: flex;
    font-weight: bold;
    margin: 15px 0px;
}
.xlsx-template{
    margin-right: 15px;
    cursor: pointer;
    color: #1D6EB4;
    text-decoration: none
}
.csv-template{
    cursor: pointer;
    color: #1D6EB4;
    text-decoration: none
}

.batch-import-section input[type="file"]::file-selector-button{
    padding:10px 25px;
    background-color:#ffffff;
    color: rgb(0, 0, 0);
    border-radius: 5px;
    margin:15px;
    cursor: pointer;
   font-weight: bold;
   border: 1px solid black;
   box-shadow: 0px 3px 6px #00000029;
   
}
.batch-import-file-input{
    width: 100%;
    display: flex;
    margin:10px 0px
}

.batch-import-section .batch-import-error-ctr{
    height: 20px;
    font-weight: bold;
    margin: 10px 0px 10px 0px;
    font-size: 15px;
    text-align: center;
    margin: 10px 0px;
}
.email-password-sent-link-str{
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.email-password-sent-link-str .sent-link-checkbox{
    width: 18px;
    height: 18px;
    margin-right: 10px;
}

.event-details{
    z-index: 901;
}
  .popUpInput{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-left: 2%;
  }

  .fileLabel{
    margin-left: 0% !important;
    width: 100% !important;
  }

  .popup-input-ctr{
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  .forlabel{
    margin-top: 2% !important;
  }
@media (max-width: 960px){   
    .batch-import-section{
        width: 45%;
    }
}
@media (max-width: 640px){   
    .batch-import-section{
        width: 55%;
    }
}
@media (max-width: 640px){   
    .batch-import-section{
        width: 95%;
    }
}
