.manage-mustering-container .modal-content{
    max-height: 90%;
    font-family: Open-Sans,sans-serif;
   
    background: white;
    border-radius: 10px;
    height: auto;
}
.manage-mustering-modal{
    width: 45%;
    height: auto;
    max-height: 97%;
    padding: 0px;
    overflow-y: auto;
    background-color: white;
    display: flex;
    flex-direction: column;
}
.manage-mustering-container .modal-content h3{
    margin-bottom: 20px;
    font-size:14px;
    letter-spacing: 2px;
    
}
.manage-mustering-section .current-location-container, .manage-mustering-section .new-location-container, .manage-mustering-section .notes-container{
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
}
.manage-mustering-section .new-location-container input[type="text"]{
    margin: 0px;
    border: 2px solid #bbbb;
    border-radius: 6px;
    padding: 12px;
    font-size: 15px;
    font-family: SF Pro Display,sans-serif;
    height: 30px;
}
.manage-mustering-section .new-location-container input[type="text"]:disabled{
    opacity: 0.5;
    cursor:  not-allowed;
}
.manage-mustering-section .notes-container .manage-mustering-text-area{
    width: 60%;
}
.manage-mustering-section  input[type="checkbox"]:disabled{
    border: #525252;
    color: rgb(158, 158, 158);
    cursor:  not-allowed;
}

.manage-mustering-section .notes-container textarea{
    width: 100%;
    height: 50px;
    border: 1px solid #606060;
    padding: 8px;
    border-radius: 6px;
    font-size: 15px;
    font-family: SF Pro Display,sans-serif;
}
.manage-mustering-section .notes-container{
    align-items: flex-start;
    margin-top:15px;
}
.manage-mustering-section .notes-container small{
    font-size: 10px;
    font-weight: normal;
    display: block;
}
.manage-mustering-section .location-exit-error{
    display: flex;
    justify-content:flex-end;
   
}
.manage-mustering-section .current-location-container span,.manage-mustering-section .new-location-container span{
    width: 60%;
}

.manage-mustering-section .location-exit-error .location-exit-error-ctr{
    position: absolute;
    width: 51%;
    font-size: 13px;
    background: transparent linear-gradient(180deg, #1661A3 0%, #032848 100%) 0% 0% no-repeat padding-box;
    color: white;
    padding: 15px ;
    border-radius: 10px;
}
.location-error-header-ctr{
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 10px;
    
}
.location-error-header-ctr svg{
    font-weight: 900;
    cursor: pointer;
}
/* .manage-mustering-section .location-exit-error div h4{
    margin-bottom: 10px;
} */
.manage-mustering-section .current-location-container label, .manage-mustering-section .new-location-container label, .manage-mustering-section .notes-container label{
    width: 100%;
}
.manage-mustering-container .manage-mustering-section .spaced-container{
    padding: 15px 20px 0px 30px;
    position: relative;
}

.mustering-label-container{
   
    display: flex;
    align-items: center;
}
.manage-mustering-container .modal-content div input{
    width: 100%;
}
.manage-mustering-container .modal-content div select{
    height: 30px;
    width: 100%;
    border-radius: 6px;
    font-size: 15px;
    color: #606060;
    font-family: SF Pro Display,sans-serif;
}
.manage-mustering-container .modal-content div select:disabled{
    cursor: not-allowed;
    opacity: 0.5;
}
.manage-mustering-container .modal-content input[type="radio"]{
    appearance: none;
    border: 2px solid #1198ED;
    background-color: white;
    width: 18px;
    height: 18px;
    margin-right: 15px;
   
}

.manage-mustering-container .modal-content input[type="radio"]:checked{
    border: 5px solid #1198ED;
}
.manage-mustering-container .modal-content .checkin-container{
    background-color: #C2F3FC;
    font-size: 14px;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center; 
}

.manage-mustering-container .modal-content .checkin-container label{
    margin-bottom: 5px;
    color: #666666;
    font-weight: bold;
}
 
.manage-mustering-container .modal-content .checkin-container input[type="checkbox"]{
    width: 18px;
    height: 20px;
}
.mustering-label-container{
    width: 50%;
}
.manage-mustering-container .modal-content .checkin-container .checkin-sub-container{
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
    justify-content: space-around;
    margin-bottom: 0px;
}
.manage-mustering-container .modal-content .checkin-container .checkin-sub-container div{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 0px;
    text-align: center;
}
.manage-mustering-container .manage-mustering-section .btn-conteiner{
    display: flex;
   justify-content: center;
   margin-bottom: 10px;
   padding: 0px;
  
}


.manage-mustering-container input[type="checkbox"]{
    border: 2px solid #333333;
    width: 18px;
    height: 20px;
    border-radius: 3px;
}
.manage-mustering-error-msg{
    text-align: center;
    margin: 10px;
    font-size: 13px;
    height: 13px;
}
.manage-mustering-section{
    height: 100%;
    display: flex;
    flex-direction: column;
}

@media (max-width: 1150px){
    .manage-mustering-container .modal-content{
        overflow-y:hidden;
        left:55%;
    }
}
@media (max-width: 1025px){
    .manage-mustering-container .modal-content{
        overflow-y:hidden;
        width: 100%;
        top:45%;
        left:55%;
    }
    .manage-mustering-section .btn-conteiner  button:nth-child(2){
        width:50%
    }
}
@media (max-width: 850px){
    .manage-mustering-modal{
        width: 70%;
    }
    .manage-mustering-container .modal-content{
        width: 100%;
        left: 58%;
    }
}
@media (max-width:769px){
    .manage-mustering-modal{
        width: 90%;
    }
    .manage-mustering-container .modal-content{
        left:50%;
    }
}
@media (max-width: 680px){
    .manage-mustering-section .current-location-container, .manage-mustering-section .new-location-container, .manage-mustering-section .notes-container{
        flex-direction: column;
        width: 100%;
        margin: 0px;
        margin-bottom: 10px;
    }
    .mustering-label-container{
        width: 100%;
    }
    .manage-mustering-section .current-location-container span,.manage-mustering-section .new-location-container span, .manage-mustering-section .notes-container div{
        width: 95%;
    }
    .manage-mustering-container .modal-content{
        width: 100%;
        max-height: 100%;
        overflow-y: auto;
    }
    .manage-mustering-section .location-exit-error .location-exit-error-ctr{
        width: 90%;
    }
    .manage-mustering-container .mustering-label-container{
        display: flex;
        width: 100%;
        margin-bottom: 5px;
    }
    .manage-mustering-section .btn-conteiner  button:nth-child(2){
        width:60%;
      

    }
    .manage-mustering-section .current-location-container label, .manage-mustering-section .new-location-container label, .manage-mustering-section .notes-container label{
        width: 100%;
        margin-bottom: 5px;
    }
    .manage-mustering-container .modal-content .checkin-container{
        flex-direction: column;
        padding: 20px;
    }
    .manage-mustering-section .current-location-container, .manage-mustering-section .new-location-container, .manage-mustering-section .notes-container{
        align-items: unset;
    }
}
@media (max-width: 680px){
    .manage-mustering-modal{
        width: 80%;
    }
    .manage-mustering-section{
        max-height: 100%;
        overflow: auto; 
    }
    .manage-mustering-section .btn-conteiner  button:nth-child(2){
        width: 60%;

    }
    .manage-mustering-section .notes-container .manage-mustering-text-area{
        width: 95%;
    }
}
