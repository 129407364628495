/* Forgot Password Page */
.forgot-password-section {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    background: transparent
        radial-gradient(closest-side at 50% 50%, #890804 0%, #550703 100%) 0% 0%
        no-repeat padding-box;
    overflow-y: auto;
}
.forgot-password-container {
    display: flex;
    flex-direction: column;
    background-color: white;
    width: 345px;
    box-shadow: 0px 20px 20px #00000014;
    padding: 30px;
    border-radius: 13px;
    font: SF Pro Display;
}
.forgot-password-container .fogot-password-container-heading {
    display: flex;
    align-items: center;
    justify-content: center;
}
.forgot-password-container h1 {
    letter-spacing: 1px;
    font-weight: 600;
    margin-bottom: 10px;
}
.forgot-password-container label {
    color: #acb1c0;
    font-size:15px ;
}
.forgot-password-container input {
    margin-bottom: 10px;
    border-radius: 6px;
}
.forgot-password-container p {
    display: block;
    margin-bottom: 10px;
    height: 12px;
    font-size: 15px;
}
.forgot-password-container .error-message {
    font-family: Roboto, sans-serif;
    color: red;
    display: block;
    margin-bottom: 5px;
    height: 12px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
}
.forgot-password-container button {
    margin-top: 70px
}
.forgot-password-container a {
    text-decoration: none;
    text-align: center;
    margin-top: 10px;
    color:#1E2432;
    letter-spacing: 1px;
}
@media (max-width:1450px) {
    .forgot-password-container{
        padding: 20px;
        width:300px;  
    }
    .forgot-password-container input {
        padding:10px
    }
}
@media (max-width: 480px) {
    .forgot-password-container {
        width: 95%;
    }
    .forgot-password-container h1 {
        font-size: 1.5rem;
    }
}
