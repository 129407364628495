/* Reset Password */
.reset-password-section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: transparent
        radial-gradient(closest-side at 50% 50%, #890804 0%, #550703 100%) 0% 0%
        no-repeat padding-box;
    overflow-y: auto;
}
.reset-password-section h3 {
    color: white;
    text-align: center;
    margin-bottom: 10px;
}
.reset-password-container {
    width: 345px;
    background-color: white;
    box-shadow: 0px 20px 20px #00000014;
    padding: 30px;
    border-radius: 13px;
    font-family: Sf Pro Display;
}

.reset-password-container h1 {
    text-align: center;
    margin-bottom: 10px;
    letter-spacing: 1px;
    font-weight: bolder;
}
.reset-password-container h4 {
    text-align: center;
    color: #acb1c0;
    margin-bottom: 10px;
}
.reset-password-container .instruction {
    color: #acb1c0;
    font-weight: normal;
}
.reset-password-container label {
    color: #acb1c0;
    font-size: 15px;
}
.reset-password-container input {
    margin-bottom: 10px;
    border-radius: 6px;
}
.reset-password-container input[type="checkbox"]{
    margin-bottom: 0px;
    margin-right: 5px;
}
.reset-password-container a {
    text-decoration: none;
    margin-top: 10px;
    color: #1e2432;
    display: flex;
    justify-content: center;
    letter-spacing: 1px;
    color:#1E2432;
}
.reset-password-container button {
    letter-spacing: 1px;
}
.reset-password-container p {
    margin: 0;
    float: left;
    font-weight: bold;
    font-size: 15px;
    display: block;
}

 .message-box {
    font-family: Roboto, sans-serif;
    color:black;
    display: block;
    margin-bottom: 5px;
    height: 30px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
}
.password-error {
    font-family: Roboto, sans-serif;
    color:rgb(194, 37, 37);
    display: block;
    margin-bottom: 5px;
    height: 30px;
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
}
@media (max-width:1450px) {
    .reset-password-container{
        padding: 20px;
        width:300px;  
    }
    .reset-password-container input {
        padding:10px
    }
}
@media (max-width: 480px) {
    .reset-password-container {
        width: 95%;
    }
    .reset-password-container h1 {
        font-size: 1.5rem;
    }
}
