.add-new-icon-model{
    width: 75%;
    height:100%;
}
.add-new-icon-modal {
    z-index: 99;
    width: 60%;
    height: auto;
    max-height: 95%;
    position: absolute;
    top: 10%;
    left:20%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.add-new-icon-modal-content-container {
    background: #FFFFFF;
    border-radius: 10px;
    padding: 15px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
}
.add-new-icon-model-container{
    padding: 5px;
}
.add-new-icon-model-title{
    padding: 5px;
    font-size: 13px;
    font-weight: bold;
}
.add-new-icon-model-icon-label{
    padding:5px;
    margin-top: 2%;
}
.add-new-icon-model-icon-label label{
    font-size: 13px;
    font-weight: bold;
}
.add-new-icon-model-icon-input-box{
    border: none;
    outline: 0;
    margin-left:2%;
    border-bottom: 2px solid #00000034;
    width: 79%;
    font: normal normal normal 14px/20px Open-Sans;
    color: #333333;
}
.file-adding-container{
    display: flex;
    margin-top: 2%;
    margin-left: 1%;
}
.file-box{
    width:57%;
}
.no-file-option{
    font-size: 12px;
    margin-top: 2%;
    margin-left: 1%;
}
.selected-img{
    width:50%;
    float: left;
    text-align: center;
    margin-top:10%;
}
.selected-img .previewimg{
    width:20%;
}
.responsive-seleted-image{
    display: none;
}
.image-restriction{
    width: 50%;
    float: right;
    padding-left: 40px;
    padding-bottom: 0px;
    margin-top:2%;
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
}
.image-restriction .img-type{
    font-size:12px;
    font-weight: bold;
    color: #000000;
}
.image-restriction .img-type-restriction{
    color: #555555;
    margin-top:2%;
}
.add-icon-cancel-btn{
    width: 30%;
    font-size: 12px;
    border-radius: 5px;
    margin-left: 5px;
    margin-bottom: 0px;
    color: #000;
    background-color: #FFFFFF;
    text-align: right;
}
.add-icon-btn-container .add-icon-submit-btn{
    width: 45%;
    background-color: #448CCB;
    font-size: 12px;
    border-radius: 5px;
    margin-left: 5px;
    margin-bottom: 0px;
    color: #FFFFFF;
    font: normal normal normal 13px SF Pro Display;
}
.inputfile{
    display: none;
}

  .add-icon-error-msg{
      text-align: center;
      width:100%;
      padding: 5px;
      justify-content: center;
      display: flex;
  }
  @media only screen and (min-width:320px) and (max-width:480px){ 
    .add-new-icon-modal{
        justify-content: flex-start;
        width: 100%;
        left:10%;
        top:5%;
    }
    .add-new-icon-model{
        width:81%;
    }
    .add-new-icon-modal-content-container{
        overflow: auto;
    }
    .file-adding-container .file-box{
        width:60%
    }

    .image-restriction{
        width:100%;
        float: left;
        padding-left: 0%;
    }
    .selected-img{
        display: none;
    }
    .selected-img-responsive{
        height:30%;
        text-align: center;
    }
    .add-new-icon-model-icon-input-box{
        width:100%;
        margin-left: 0%;
    }
    .file-box{
        width:50%
    }
    .add-icon-submit-btn{
        width: 50%;
    }
    .no-file-option{
        display: none;
   }
   .responsive-seleted-image{
       display: block;
   }
   .responsive-seleted-image .previewimg{
       width:10%;
       
   }
   .responsive-seleted-image{
        display: block;
        text-align: center;
        margin:5%;
    }
    .responsive-seleted-image .previewimg{
        width:20%;
    }
}
  @media only screen and (min-width:481px) and (max-width:768px){
    .add-new-icon-modal-content-container{
        overflow: auto;
    };
    .file-box{
        width:70%
    }

    .image-restriction{
        width:100%;
        float: left;
        padding-left: 0%;
    }
    .selected-img{
        display: none;
    }
    .selected-img-responsive{
        height:30%;
        text-align: center;
    }
    .no-file-option{
         display: none;
    }
    .add-new-icon-model .add-new-icon-model-container .add-icon-btn-container{
        padding-bottom: 1%;
    }
}
  @media only screen and (min-width:481px) and (max-width:768px){
    .add-new-icon-modal-content-container{
        overflow: auto;
    }
    .add-new-icon-model-icon-input-box{
        width:100%;
        margin-left: 0%;
    }
    .add-new-icon-modal{
        width:69%;
        left:15%
    }
    .responsive-seleted-image{
        display: block;
        text-align: center;
        margin:5%;
    }
    .responsive-seleted-image .previewimg{
        width:20%;
    }
}
  @media only screen and (min-width:769px) and (max-width:990px){
    .add-new-icon-modal-content-container{
        overflow: auto;
    }
    .add-new-icon-model{
        width: 100%;
        height:70%
    }
    
}
@media only screen and (min-width:990px) and (max-width:1024px){
    .add-new-icon-modal-content-container{
        overflow: auto;
    }
    .add-new-icon-modal{
    }
}
@media(min-width:1440px) {
    .add-new-icon-modal{
        width:50%;
        left:25%;
        height:max-content
    }
}
