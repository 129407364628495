.add-group-modal{

}
.hide-role-filter{
    opacity: 0.3;
}
.hide-role-filter:hover{
    cursor: not-allowed;
}
.hide-role-filter select:hover{
    cursor: not-allowed;
}
.modal-title{
    color: #000;
    font: normal normal 700 14px SF Pro Display, sans-serif;
    text-align: left;
} 
.group-name-container{
    width: 100%;
}
.modal-inner-container{
    height: 90%;
    flex: 1;
}
.response-container{
    height: 18px;
    color: #890804;
    text-align: center;
    font-size: 12px;
    font-weight: bold;
    font-family: Open-Sans,sans-serif;
}
.group-name-input{
    border:none;
    outline: 0;
    padding: 3px;
    border-bottom: 2px solid #00000034;
    width:50%;
    font-size: 15px;
}
.group-filter-inner-container{
    width: 100%;
    padding-top:5px;
}
.group-filter-inner-container select{
    width: 30%;
    border-radius: 5px;
}
.group-filter-select{
    width: 30%;
}
.groups-select-container{
    width: 80%;
}
.group-filter-search{
    width: 30%;
    border-radius: 5px;
    border: 1px solid #bbbb;
    background: url('../../../assets/svg/navIcons_search.svg') no-repeat right 3% center;
    background-size: 16px;
    min-height: 25px;
    padding: 4px 25px 4px 4px;
    margin-left: 25px;
}
.group-filter-container{
    width: 100%;
}
.group-filter-container-title{
    color: #000;
    font: normal normal 700 14px SF Pro Display, sans-serif;
    padding-bottom: 5px;
    text-align: left;
    margin-top:2%;
}
.user-list-select-container{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}
.user-list-select-container-list{
    width: 100%;
    display: flex;
    justify-content: space-between;
    height: 65%;
    padding-bottom: 5px;
    margin-top:2%
}
.user-list-unselected-container{
    width: 45%;
    display: flex;
}
.user-list-selected-container{
    width: 45%;
    display:flex;
}
.user-list-select-checkbox-container{
    display: flex;
    margin-left: 20px;
    align-items: center;
}
.user-list-select-checkbox-container input{
    margin-left: 10px;
    width: 18px;
    height: 18px;
}

.user-list-unselected-container-list{
    width: 50%;
    padding: 5px;
    height: 100%;
}
.user-list-option-container-list{
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.user-list-option-container-list img{
    margin-bottom: 15px;
    cursor: pointer;
    height: 25px;
}
.user-list-selected-container-list{
    width: 50%;
    padding: 5px;
    height: 100%;
}
.user-list-select-container-title{
    font: normal normal 700 13px SF Pro Display, sans-serif;
    font-weight: 600;
}
.ccard{
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 4px 9px 5px rgb(0 0 0 / 20%);
    border-radius: 13px;
    padding: 10px;
    height: 100%;
    min-height: 200px;
    overflow: auto;
}
.user-list-row{
    display: flex;
    width: 100%;
    margin-bottom: 7px;
    font-size: 14px;
}
.user-list-checkbox {
    height: 25px;
    width: 25px;
    width: 10%;
}
.user-list-checkbox .container {
    position: relative;
}
.user-list-checkbox .container input[type=checkbox]{
    cursor: pointer;
    opacity: 0;
    position: absolute;
}
.container input[type=checkbox]:checked~.checkmark {
    border: 5px solid #1198ed;
}
.user-list-checkbox .container .checkmark{
    background-color: #fff;
    border: 2px solid #1198ed;
    border-radius: 50%;
    height: 20px;
    position: absolute;
    width: 20px;
    cursor: pointer;
}
.user-list-name{
    width: auto;
    cursor: pointer;
}
.user-list-email{
    width: 50%;
}
.popup-button-container{
    margin-top: 10px;
    margin-bottom: 3px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #f1f1f1; 
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; 
}

.responsive {
    display: none;
}
.group-page .col-12{
    margin-right: 10px;
}
.groups-select-filter-container{
    width: 20%;
}
.group-page .col-12 select{
    width: 100%;  
}
.user-list-select-container-list .icon{
    padding:10px;
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    justify-content: center;
}
@media only screen and (max-width:768px) {
    .user-list-name{
        width: auto;
        margin-left: 3%;
    }
}
@media only screen and (max-width:600px) {
    .modal-content-container{
        width: 90%;
    }
    .group-name-input{
        width: 98%;
    }
    .group-filter-select{
        width: 100%;
    }
    .group-filter-search{
        width: 100%;
        margin-left:1%;
        margin-top: 3%;
    }
    .user-list-select-container{
        flex-direction: column;
        padding-top:2%;
        /* padding-left:12%; */
    }
    .user-list-select-container-list{
        flex-direction: column;
    }
    .user-list-unselected-container-list {
        width:100%;
        height: 300px;
    }
    .add-group-modal .user-list-option-container-list{
        flex-direction: row;
        padding-left: 50%;
        padding-top: 2%;
    }
    .user-list-selected-container-list{
        width: 100%;
        min-height: 50%;
    }
    .popup-button-container{
        margin-top:5%;
        width: auto;
    }
    .responsive {
        display: block;
    }
    .user-list-selected-container {
        display: none;
    }
    .user-list-select-container-title{
        white-space: nowrap;
    }
    .user-list-select-checkbox-container{
        white-space: nowrap;
    }
    .groups-select-container{
        width: 100%;
    }
    .columns .col-12 {
        width: 100%;
    }
    .top-columns .col-14{
        padding: 1px;
        text-align: left;
    }
    .add-group-modal .columns .col-33{
        width: 100%;
}
}
.add-group-modal, .manage-role-modal{
    height: auto; 
    max-height: 95%; 
    display: flex;
    flex-direction: column;
}
.Add-user-content-container  .item-listing-box{
    flex-direction: column;

}
.add-group-modal-content{
    max-height: 99%;
    overflow: auto;
}
@media only screen and (max-width:768px) {
    .user-list-name{
        width:90%;
        padding-left:5%;
    }
    .modal-inner-container{
    margin-left: 0px;
    }
}
@media(max-width:1024px){
.user-list-select-container-list{
    height:70%
}
}
@media only screen and (min-width: 1440px) {
    .add-group-modal, .manage-role-modal{
        width: 65%;
    
    }
    .user-list-select-container-list{
        height: 65%;
    }
    .modal-title{
        height: 5%;
    }
}
@media screen and (max-width: 768px){
    .group-page .col-33{
        width: 40%;
    }
    .group-page .col-12{
        width: 40%;  
    }
}
@media only screen and (max-width: 600px){
    .group-page .col-12{
        width: 40%;
    }
    .modal-inner-container{
        height:auto;
    }
    .group-filter-inner-container{
        width: 98%;
    }
    .group-filter-inner-container select{
        width: 100%;
    }
    .user-list-select-container-list .icon {
        padding: 0%;
        flex-direction: row;
        justify-content: center;
        background-color: rgba(255,255,255,.5);
    }
    .user-list-select-container-list  .icon svg{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        margin-bottom: 10px;
        cursor: pointer;
      
    }
    .user-list-unselected-container-list {
        width:100%;
        height: 250px;
    }
}
@media only screen and (max-width:480px){
    .group-page .col-33{
        width: 45%;
    }
    .group-page .col-12{
        width: 45%;
    
    }
    .user-list-unselected-container-list {
        width:100%;
    }
.user-list-unselected-container-list .item-listing-box{
    width:100%
}
.user-list-selected-container-list .item-listing-box{
    width:100%
}
}

