.download-content-container{
    height: auto;
    width: 48%;
    max-height: 95%;
    max-height: 95%;
    overflow-y: auto;
}
.download-content-section{
    padding:10px;
    font-family: Open-Sans,sans-serif;
    font-size: 14px;
    /* max-height: 95%;
    overflow-y: auto; */
}
.download-content-title{
    font-weight: bold;
    margin-bottom: 15px;
}
.download-content-row-element{
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;
    align-items: flex-end;
    margin-bottom: 20px;
}

.field-type-label{
    cursor: pointer;
}
.download-radio-container{
    display: flex;
    align-items: center;
}
.download-radio-container label{
    margin-right: 10px;
}

.download-content-row-element input[type="text"]{
    width: 60%;
    margin: 0px;
    margin-left: 10px;
    border-radius: unset;
}
.download-content-row-element input[type="radio"]{
    margin-right: 5px;
}
.download-report-label{
    font-weight: bold;
}
.download-btn-row{
    justify-content: center;
    margin-bottom: 0px;
    margin-top:10px
}
.download-btn-row button{
    font-weight: normal;
    font-size: 14px;
    font-family: inherit;
}


.download-content-container .download-report-error-ctr{
    height: 15px;
    font-weight: bold;
    font-size: 12px;
    text-align: center;
    font-family: 'Open-Sans';
}
    

@media (min-width: 1440px){   
    .download-content-container{
        width: 35%;
    }
}
@media (max-width: 960px){   
    .download-content-container{
        width: 60%;
        
    }
    .download-content-row-element input[type="text"]{
        width: 70%;
        margin: 0px;
    }
    .download-content-field-name{
        width: 20%;
    }
}
@media (max-width: 640px){   
    .download-content-container{
        width: 80%;
    }
}
@media (max-width: 480px){   
    .download-content-container{
        width: 90%;
    }
    .download-content-field-name{
        width: 100%;
        margin-bottom: 10px;
    }
    .download-content-row-element input[type="text"]{
        margin: 0px;
        width: 100%;
    }
}
