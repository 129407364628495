.display-column {
  display: flex;
  flex-wrap: wrap;
}

.device-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: auto;
  font-family: Open-Sans, sans-serif;
}
.status-tab-container p:hover{
  cursor: pointer;
}
.addDevice-content {
  height: auto;
}
.upload-file-icon-div {
  width: 18%;
  margin: 6px;
  display: flex;
  align-items: center;
}

.device-selection-text-container {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  font-size: 14px;
  width: 100%;
  padding-top: 8px;
  font-family: "Open-Sans";
}

.option-title {
  padding-right: 20px;
  font-weight: bold;
}

.rowDivSearch{
  width: 25%;
  margin-right: 6px;
  display: flex;
  flex-direction: row;
}
.device-status-search-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Open-Sans, sans-serif;
  width: 100%;
  margin-top: 15px;
  justify-content: space-between;
}
.searchReset{
  justify-content: center;
  align-items: center;
  padding-bottom: unset;
}
.status-tab-container 
{
  width: 55%;
  display: flex;
  flex-direction: row;
  font-size: 14px;
}
.device-page-search-container{
  width:100%;
}
.device-search {
  margin-left: 2%;
  margin-top: 1%;
  width: 88%;
  height: 26px;
  border: 1px solid #a3a0a0;
  border-radius: 7px;
  padding: 5px;
  font-family: inherit;
  border-width: 2px;
}

.vertical-line-status-tab {
  border-left: 1px solid #7c7a7a38;
  height: 22px;
  margin-left: 22px;
  margin-right: 22px;
}

@media (min-width: 1240px) and (max-width: 1340px) {
  .status-tab-container {
    width: 75%;
  }
  .rowDivSearch{
    width: 25%;
  }
}
@media (min-width: 1025px) and (max-width: 1240px) {
  .status-tab-container {
    width: 75%;
  }
  .rowDivSearch{
    width: 35%;
  }
}

@media (max-width: 960px) {
  .col-33 {
    width: 40%;
  }

  .vertical-line {
    width: 11px;
  }

  .device-status-search-container {
    flex-direction: column;
    align-items: unset;
    width: 100%;
  }

  .device-selection-text-container {
    flex-direction: column;
  }
 .rowDivSearch{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
  width: 60%;
 }.searchReset{
  align-items: center;
  display: flex;
 }
  .filter-text {
    margin-top: 10px;
  }

  .status-tab-container {
    width: 100%;
  }

  .col-33 {
    width: 50%;
  }
}

@media (max-width: 640px) {
  .col-33 {
    width: 44%;
  }

  .vertical-line {
    width: 16px;
  }
  .vertical-line-status-tab{
    margin-left: 10px;
    margin-right: 10px;
  }

  .device-status-search-container {
    flex-direction: column;
    align-items: unset;
    width: 100%;
  }

  .device-selection-text-container {
    flex-direction: column;
  }

  .filter-text {
    margin-top: 10px;
  }

  .status-tab-container {
    width: 100%;
  }

  .col-33 {
    margin-top: 10px;
    width: 80%;
  }
  .status-tab-container{
    flex-flow: wrap;
  }
}

@media (max-width: 480px) {
  .vertical-line-status-tab{
    margin-left: 5px;
    margin-right: 5px;
  }

  .col-33 {
    width: 60%;
  }

  .vertical-line {
    width: 11px;
  }

  .device-status-search-container {
    flex-direction: column;
    align-items: unset;
    width: 100%;
  }

  .status-tab-container {
    width: 100%;
  }

  .col-33 {
    margin-top: 10px;
    width: 100%;
  }
}

@media (max-width: 340px) {
  .vertical-line-status-tab{
    margin-left: 2px;
    margin-right: 2px;
  }
  .device-status-search-container {
    flex-direction: column;
    align-items: unset;
    width: 100%;
  }

  .status-tab-container {
    width: 100%;
    font-size: 12px;
  }

  .col-33 {
    margin-top: 10px;
    width: 100%;
  }

  .vertical-line-status-tab {
    margin-left: 5px;
    margin-right: 5px;
  }
  .rowDivSearch{
    width: 100%;
  }
  .rowDivSearch{
    width: 100%;
  }
}
