.specific-event-history-container{
    height: 100%;
    width: 100%;
    overflow: auto;
    font-size: 14px;
}
.specific-event-history-container input[type="checkbox"]{
    border: none;
    cursor: default;
}
.specific-event-history-container .top-content{
    height: auto;
}
.specific-even-top-content-title{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
}
 .alarm-cleared-by-name-ctr, .alarm-triggered-by-name-ctr{
    font-weight: bold;
 }  
 .alarm-cleared-by-ctr, .alarm-triggered-by-ctr {
    margin-bottom: 10px;
 }
 .alarm-triggered-details-ctr{
    font-family: Open-Sans,sans-serif;
    font-size:14px;
    /* margin-bottom: 10px; */
    display: flex;
    flex-wrap: wrap;
}
.alarm-triggered-by-ctr{
    margin-right: 30px;
}
.download-report-btn-field{
    margin-bottom: 10px;
}
.specific-even-top-content-row-1{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}
.status-bar-conatiner{
    display: flex;
    flex-wrap: wrap;
    margin-top: 10px;
}
.specific-event-history-container{
    overflow: auto;
}
.status-bar-conatiner div{
    margin-right: 10px;
    margin-bottom: 10px;
}
.specific-even-top-content-title p{
    font-size: 14px;
    font-weight: bold;
}

.back-to-history{
    margin-left: auto;
}
.status-bar-conatiner .back-to-history button{
    background-color: white;
    white-space: nowrap;
    cursor: pointer;
    color: black;
    border: none;
    font-family: SF Pro Display, sans-serif;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.download_icon{
    height: 18px;
    margin-left: 9px;
}
.download_btn{
    padding: unset;
    height: 40px;
}
@media (max-width: 1210px) {
    .specific-event-history-container .top-content{
        margin-top: 10px;
    }
}

@media (max-width: 480px){   
    .specific-event-history-container .table-page-entry-container{
        justify-content: center;
        margin-top: 10px;
    }
     
}
