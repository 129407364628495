.nav-container{
   /*  width: 16%; */
    height: 90%;
}
.right-container{
    width: 80%;
}
.signIn-button{
    background-color: #550703 !important;
    cursor: pointer;
    display: inline-block ;
    font-family: inherit !important;
    font-size: 15px !important;
    font-weight: 700 !important;
    letter-spacing: 1px;
}
.signIn-button:hover{
    background-color: #990804 !important;
}

@media  screen and (max-width:768px) {
   .nav-container{
       width: 0%;
   }
    .right-container{
        width: 100%;
    }
}

@media (min-width: 1025px) and (max-width:1440px){ 
    .nav-container{
        width: 20%;
    }
    .right-container{
        width: 80%;
    }
}

@media  screen and (min-width:769)and (max-width:1024px) {
  
    .nav-container{
        width: 30%;
    }
    .right-container{
        width: 70%;
    }
   
}
