.server-configuration{
    overflow: auto;
}

.server-configuration h3{
    font-size: 14px;
    font-family: Open-Sans,sans-serif;
    margin: 20px 0px;
}

.server-configuration-container{
    padding: 20px;
    margin: auto;
    box-shadow: 0px 10px 20px #00000034;
    font-family:  Poppins-regular,sans-serif;
    letter-spacing: 1px;
    margin-bottom: 20px;
}

.server-configuration-container h5{
    color: #448CCB;
    font-weight: bold;
}
.server-configuration-container input, .deployment-input-ctr input, .manage-device-details-ctr input[type="text"]{
    box-shadow: 0px 5px 8px #00000029;
    border: 1px solid #333333;
    border-radius: 4px;
    padding: 3px;
    width: 65%;
    display: block;
   margin: 0px;
   margin-bottom: 15px;
   margin-top: 10px;
}
.server-configuration-container input.invalid, .deployment-input-ctr input.invalid{
    border: 1px solid #890804 !important;
}
.input-error-message{
	height: 10px;
	font-size: 11px;
	text-align: right;
	color: #890804;
}
.server-configuration-container input::placeholder, .deployment-input-ctrinput::placeholder{
    color:#CCCCCC;
    font-size: 14px;
}
.server-configuration-container input[type="radio"]{
    width: auto;
    display: inline;
    box-shadow: none;
}
.server-configuration-container label{
    font-size: 12px;
    font-weight: bold;
}
.server-button-container {
    width: 450px;
    display: flex;
    justify-content: center;
}

.server-button-container button:nth-child(2){
    width: 40%;
    background-color: #304455;
    border-radius: 5px;
    margin-left: 2%;
}
.server-configuration-container .server-type{
    display: flex;
     margin-top: 10px;
}
.server-configuration-container .radio-button-group{
    display: flex;
    align-items: center;
    margin-right: 10px;
    
}
.server-configuration-container fieldset{
    border: none;
}
.server-configuration-container .radio-button-group input{
    margin: 0px;
    margin-right: 5px;
}
.server-type h5{
    border-bottom: 10px;
}
.server-type input[type="radio"]{
    appearance: none;
    border: 2px solid #1198ED;
    background-color: white;
    width: 12px;
    height: 12px;
    margin-right: 15px;
    border-radius: 50%;
}
.server-type input[type="radio"]:checked{
    border: 4px solid #1198ED;
}
.sara-credentials,
.ums-credentials,
.mqtt-credentials{
    width:30%;
}
.server-configuration-inner-container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
.server-config-button-container{
    width: 75%;
    display: flex;
    padding: 10px;
    align-items: center;
    /* justify-content: center; */
    margin: auto;
}
.update-error-message{
	height: 20px;
	font-size: 13px;
    font-weight: bold;
	text-align: center;
	color: #890804;
    margin-right:5%
}
.server-config-row-2{
    margin-top: 25px;
    width: 100%;
}
.media-credentials{
    width: 30%;
}
.region-select{
    display: flex;
    flex-direction: column;
}
.region-select select{
    margin-top: 8px;
    width: 100%;
    box-shadow: 0px 5px 8px #00000029;
    border: 1px solid #333333;
    border-radius: 4px;
    padding: 3px;
    font-size: 0.95rem;
}
.invalid-select{
    border: 1px solid #890804 !important;
}
@media (min-width:769px) {
    .server-configuration-container{
        font-size: 12px;
    }   
    .server-configuration h3{
        margin: 10px;
    }
    .server-configuration-container input{
       margin-top: 7px;
       margin-bottom: 7px;
       width: 100%;
    }
    .server-configuration-container label {
        font-size: 11px;
    }
    
    .server-button-container{
        width:60%
    }
    .server-button-container button{
        margin: 0px;
    }
}

@media (max-width:768px){
    .sara-credentials,
    .ums-credentials,
    .mqtt-credentials,
    .media-credentials {
        width: 45%;
    }
    .server-configuration-container input{
        width:100%
    }
}

@media (max-width: 450px){
    .sara-credentials,
    .ums-credentials,
    .mqtt-credentials,
    .media-credentials{
        width:100%;
    }
    .server-configuration-container input{
        width:100%
    }
    .server-configuration-container{
        width: 100%;
        box-shadow: none;
    }
    .server-button-container{
        justify-content: center;
        width: 100%;
    }
}
