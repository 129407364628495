.add-mustering-location-ctr{
    height: auto;
    max-height: 95%;
    display: flex;
    flex-direction: column; 
}
.add-mustering-location-section{
    font-family: Open-Sans,sans-serif;
    font-size:14px;
    max-height: 99%;
    overflow: auto;
   
}
.add-mustering-loc-btn-ctr 
.group-management-container{
    margin: 10px 0px;
}
.add-mustering-location-ctr .group-management-card-container{
    display: flex;
    margin-bottom: 10px;
}
.add-mustering-location-ctr .card-search-container{
    display: flex;
}
.add-mustering-loc-btn-ctr{
    margin-top: 10px;
    display: flex;
    justify-content: center;
}
.add-mustering-location-title{
    font-weight: bold;
    margin-bottom: 5px;
}

.add-mustering-location-label{
    font-weight: bold;
}
.add-mustering-location-input{
    width: 50%;
    font-family: inherit;
}
.add-mustering-location-input input{
    border-radius: 0px;
}
.add-mustering-location-ctr .user-list-row{
    padding: 2px;
    margin-bottom:3px;
    cursor: pointer;
    min-height: 25px;
}

@media (max-width: 960px){   
    .add-mustering-location-ctr{
        width: 80%;
    }
}
@media (max-width: 640px){   
    .add-mustering-location-ctr{
        width: 70%;
        height: 95%;
    } 
}
@media (max-width: 480px){   
    .add-mustering-location-ctr{
        width: 90%;
        height: 97%;
    } 
    .add-mustering-location-section{
        padding-right: 5px;
    }
}
