.drpdwn-outer{
    width: 50%;
    position: relative;
}

.drpdwn-inner{
    z-index: 4;
    position: absolute;
    overflow: hidden;
    max-height: 200px;
    width: 100%;
    background-color: white;
    border-bottom: solid 1px;
    border-right: solid 1px;
    border-left: solid 1px;
    display: flex;
    flex-direction: column;
}

.drpdwn-search{
    color: black;
    border: 2px solid #bbbb;
    font-size: 13px;
    width:100%;
    background-size: 12px;
    border-radius: 5px;
    background: url('../../assets/svg/navIcons_search.svg') no-repeat right 2% center;
    padding:3px 5px;
}

.drpdwn-input {
    cursor: pointer;
    display: flex;
    padding:3px 5px;
    min-width: 100%;
    border:2px solid #bbbb;
    border-radius: 5px;
}
.drpdown-content{
    overflow: auto;
}
.input-content{
    width: 90%;
    display: flex;
    align-items: center;
}
.cursorpointer::placeholder{
    color:black;
}
.drpdwn-content{
    border: solid 2px black;
    padding: 5px;
}
.list-item {
    font-size: 100%;
    padding:3px 2px;
    min-height: 25px;
}

/* .list-item:hover{
    color: white !important;
    background-color: #289af5 !important;
} */

.active-option{
    color: white;
    background-color: #289af5;
}

/* .drpdown-content .active {
    color: white;
    background-color: #289af5;
} */