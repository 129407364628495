.popUpContainer {
  z-index: 99;
  width: 60%;
  height: auto;
  max-height: 95%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}
.form-audio {
  height: 100%;
}
.form-audio > .add-new-icon-model-title {
  height: 8%;
}
.form-audio > .file-adding-container {
  height: 25%;
}
.form-audio > .supportedFileText {
  height: 15%;
}
.form-audio > .selected-tone {
  height: 15%;
}
.form-audio > .add-icon-error-msg {
  height: 8%;
}
.error-input {
  border-bottom: 2px solid #890804;
}
.add-new-audio-model {
  width: 75%;
  height: 100%;
}
.title-cont-second > .no-file-option {
  margin-top: 3.5% !important;
}
.upload-modal-container {
  background: #ffffff;
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
.supportedFileText {
  font-family: Open-Sans, sans-serif;
  font-size: 13px;
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 5px;
  color: #555555;
  flex-direction: column;
  width: 43%;
}
.supportedFileText div {
  color: #000000 !important;
}
.previewAndFormat{
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
}
.tone-file-input::placeholder {
  color: #606060;
}
.selected-tone {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  height: 40px;
  width: 52%;
}
.tone-label {
  color: #606060;
  font-weight: bold;
}
.audio-popup-cont {
  width: 100%;
}
.title-cont {
  display: flex;
  flex-direction: row;
}
.audio-choose-position {
  margin-top: 10px;
  margin-left: 20px;
}
.toneName-input {
  width: 70% !important;
  margin-top: 2%;
}
.tone-file-input {
  width: 50% !important;
  margin-left: 1% !important;
  color: #606060;
  font-family: Open-Sans, sans-serif;
  font-size: 14px;
}

.title-cont-second {
  margin-top: 3%;
}

.errorMessageDiv{
  height: 50px;
  align-items: end;
  display: flex;
}

.borderChange {
  border-style: unset !important;
}

@media(min-width: 1440px) {
  .popUpContainer {
    width: 50%;
  }
}

@media screen and (min-width: 250px) and (max-width: 900px) {
  .popUpContainer {
    height: auto;
    width: 100%;
  }
}
@media screen and (min-width: 200px) and (max-width: 550px) {
  .title-cont-device{
    flex-direction: column;
  }
  .toneName-input{
    width: 100% !important;
  }
}
@media screen and (min-width: 200px) and (max-width: 500px) {
  .previewAndFormat {
    flex-direction: column;
    margin-left: 9px;
  }
  .supportedFileText{
    width: 80%;
  }
  .selected-tone {
    width: 100%;
  }
  .selected-tone{
    order: 2;
   justify-content: flex-start;
  }
}
