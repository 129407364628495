.user-profile-container{
    box-shadow: 0px 10px 20px #00000034;
    padding:20px;
    width: 45%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    border-radius: 10px;
    font-family: Open-Sans,sans-serif;
    font-size: 14px;
}
.user-profile-section{
    overflow: auto;
}
.change-pic-span{
    font-weight: bold;
    cursor: pointer;
}
.user-profile-title{
    font-weight: bold;
    margin-top: 10px;
    font-size: 16px;
}
.user-profile-sub-ctr-1{
    display: flex;
}
.user-profile-sub-ctr-1 .image-container{
    display: flex;
    flex-direction: column;
}
.user-profile-input-ctr{
    width: 65%;
}
.user-profile-sub-ctr-2{
    margin-top: 15px;
}
.user-profile-sub-ctr-1 .image-container img{
    width: 80%;
    height: 65%;
    margin-bottom: 10px;
}
.profile-page-btn-ctr{
    display: flex;
    justify-content: flex-end;
}
.user-profile-sub-ctr-1 input{
    margin-bottom: 0px;
    margin-right: 0px;
    font-family: inherit;
    border-radius: 0px;
}
.inner-image-ctr{
    position: relative;
    width: 140px;
    height: 170px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.inner-image-ctr .inner-x{
    position: absolute;
    font-size: 20px;
    background-color: rgb(114, 114, 114);
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    right: 20px;
    bottom: 45px;
    color: white;
    font-weight: normal;
    border: 5px solid white;
}
.profile-role-ctr, .profile-advisor-ctr{
    margin-bottom: 10px;
}

.profile-role-label, .profile-advisor-label, .reset-password-link-ctr{
    font-weight: bold;
    margin-bottom: 5px;
}
.profile-role-input,.profile-advisor-input{
    margin-left: 10px;
}
.reset-password-link-ctr{
    cursor: pointer;
    color:#1e5fc7;
    margin-top: 30px;

}
.profile-err-msg{
    text-align: center;
}
.user-profile-sub-ctr-1 input{
    font-size: 13px;
}

@media (min-width: 1440px){   
    .user-profile-container{
        width: 35%;
    }
   
}
@media (max-width: 960px){   
    .user-profile-container{
        width: 55%;
    }
    
}
@media (max-width: 640px){   
    .user-profile-container{
        width: 85%;
    }
  
}
@media (max-width: 480px){ 
    .user-profile-container{
        padding: 10px;
    }
    .user-profile-container{
        width: 100%;
    }
   
    .user-profile-sub-ctr-1{
        flex-direction: column;
    }
    
    .user-profile-sub-ctr-1 .image-container{
        width: 100%;
    }
    
    .user-profile-input-ctr {
        width: 100%;
        margin-bottom: 10px;
    }
    .user-profile-input-ctr input{
        margin-bottom: 5px;
    }
    
}
