.account-page-container{
	width: 100%;
	height: 100%;
	overflow: auto;
}
.account-action-container{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.account-search-input{
	color: black;
	border: 2px solid #bbbb;
	font-size: 13px;
	border-radius: 5px;
	padding:3px 5px;
	background: url('../../assets/svg/navIcons_search.svg') no-repeat right 3% center;
	background-size: 16px;
	margin-right: 5px;
}
.add-account-modal{
	width: 60%;
	height: auto;
	max-height: 95%;
	display: flex;
	flex-direction: column;
}
.account-form-container{
	width: 100%;
}
.account-label-input-container{
	width: 100%;
	display: flex;
	padding: 10px;
	flex-wrap: wrap;
	padding-bottom: 0px;
}
.account-label-container{
	width: 35%;
	font-size: 14px;
}
.account-input-container{
	width: 65%;
}
.input-error-message{
	height: 10px;
	font-size: 11px;
	text-align: right;
	color: #890804;
}
.account-input{
	outline: none;
	border: none;
	color: black;
	border: 2px solid #bbbb;
	font-size: 13px;
	border-radius: 5px;
	padding: 3px 5px;
	width: 100%;
}
.account-input.invalid{
	border: 2px solid #890804 !important;
}
.account-input:active,
.account-input:focus{
	border: 2px solid #000;
}

.account-inner-form-container{
	width: 99%;
	margin-left: 1%;
}
.account-inner-form-title{
	font-size: 14px;
	font-weight: bold;
}
.account-filter-ctr{
	display: flex;
	align-items: center;
	margin-right: 10px;
	margin-top: 12px;
}
@media only screen and (max-width:768px) {
	.account-label-container{
		width: 100%;
	}
	.account-input-container{
		width: 100%;
	}
	.account-label-input-container{
		padding: 5px;
	}
	.add-account-modal{
		width: 90%;
	}
}

@media only screen and (min-width:1440px) {
	.add-account-modal {
		/* height: 81%; */
		width: 50%;
	}
}


@media only screen and (max-width:480px) {
	.account-search-input{
		margin-right: 0px;
	}
	
}
