.card-cotainer{
display:flex;
font-family: Open-Sans,sans-serif;
}
.item-listing-box{
border: 1px solid #00000027;
transition: 0.3s;
width: 100%;
height: 250px;
border-radius: 10px;
}
.card-container .item-listing-box .card-container-head{
display: flex;
padding:10px;
border-top-right-radius: 5px;
border-top-left-radius: 5px;
}
.select-header{
    background-color: #448CCB;
}
.deselect-header{
    background-color: #000000;
}
.card-container-title{
color: white;
font-family: Open-Sans,sans-serif;
font-size: 13px;
}
.card-select-box{
color: white;
font-family: Open-Sans,sans-serif;
font-size: 13px;
display: flex;
margin-left: auto;

align-items: center;
}
.card-select-box input[type="checkbox"]{
  width: 15px;
  height: 15px;
  font-size: 10px;
  border: 1px white solid;
}
.card-select-box label{
padding-right:5px;
}
.card-select-box .select-box-color{
width:20px;
height:20px;
border: 2px solid white;
background-color:#448CCB;
color:white
}
.card-select-box .deselect-box-color{
    width:20px;
    height:20px;
    border: 2px solid white;
    background-color: #000000;
    color:white
 }
.card-filter {
padding: 5px;
background-color: white;
border-bottom: 1px solid #00000027;
}
.card-filter img{
  margin-left:2%;
  margin-top: 2%;
  width:20px
}
.filter-container{
  display:flex;
}
.card-filter input[type="search"]{
  margin-left: 2%;
  margin-top: 1%;
  width:88%;
  height: 30px;
  border: 1px solid #E8E7E7;
  border-radius: 7px;
  padding:10px;
  font-family: inherit;
}
.card-filter input[type="search"]::placeholder{
  padding-left: 6px;
  font: normal normal medium 14px/28px SF Pro Display;
}
.card-container .item-listing-box .list-item-container{
    overflow: auto;

}
.list-item-container select{
  border:none; 
  outline: none;
  overflow: auto;
  width:98%;

  background:none;
}
.card-data-list .user-list-row,.filter-card-data-list .user-list-row{
  cursor: pointer;
  padding: 5px;
  margin-bottom: 2px;
  font-family: Open-Sans,sans-serif;
  font-size: 13px;
}
.list-item-container select[multiple] option:checked {
  background: #707070 linear-gradient(0deg, #707070 0%, #707070 100%);
  color: white;
}
.filter-card-data-list{
  padding: 10px;
  overflow: auto;
  margin: 0px;
  width: 100%;
  border: none;
  border-radius: 0px;
  height: 80%;
}
.filter-card-data-list:focus{
  outline: 0px;
}
.filter-card-data-list::-ms-expand {
  display: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}
.list-items{
  font-weight:normal;
  font-size:12px;
  padding:2px;
  margin-left:2%;
  font-family: Open-Sans,sans-serif;
  cursor: pointer;
}
.list-item-container input[type="checkbox"]{
  display: none;
}
.card-data-list{
  overflow: auto;
  padding: 10px 5px;
  height: 62%;
  width: 100%;
  border: none;
  margin: 0px;
  
}

.card-data-list:focus{
  outline:0px
}
select[multiple] option:checked {
  background: rgb(134, 127, 127) linear-gradient(0deg, rgb(126, 124, 124) 0%, rgb(102, 102, 102) 100%);
  color: white;
}

select[multiple]{
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
}
select[multiple]::-ms-expand {
  display: none;
}
.card-container{
  width: 100%;
}
.card-data-list-mobile{
  display: none;
}
.card-list-mobile, .filter-list-mobile {
  display:none;
}
@media(max-width:320px){
.item-listing-box{

  height: 250px;
 }
 .card-container .item-listing-box .card-container-head{
   height:18%
 }
}
@media (max-width: 1024px){   
  .card-data-list, .filter-card-data-list{
   display: none;
  }
  .card-list-mobile, .filter-list-mobile {
    display: block;
  }
}
@media (max-width: 480px){  
  .item-listing-box{
      width: 90%;
  }
}