.roles-page{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow: auto;
}
.roles-container{
    display:flex;
    justify-content: space-between;
}
.roles-listing-conatiner{
    width:34%;
}
.role-card-ctr{
    width: 48%;
}

.creating-roles-conatiner{
    width:65%;
    margin-right: 10px;
    margin-top: 10px;
}
.create-role-description{
margin-top:5%;
font: normal normal normal 13px/18px SF Pro Display;
color: #ACB1C0;
}
.roles-list{
margin-top:5%
}
.roles-list .table-container .table-options-container{
    display: flex;
    flex-direction: column;
}
.roles-list .table-container .table-page-entry-container{
    justify-content: flex-end;
}
.roles-list .table-container .table-entry-container{
    margin: 10px 10px 10px 0px;
}
.roles-list .table-container .table-inner-container .table-body .table-data-row .table-data{
height:52px;
border-bottom: none;
}
.roles-container input[type="text"]::placeholder,.roles-container input[type="text"]{
    color:#448CCB;
    font-weight: bold;
    letter-spacing: 1px;
}
.role-header-input{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}
.role-checkbox-ctr{
    display: flex;
    align-items: flex-start;
    margin-right: 20px;
}

.role-checkbox-ctr input[type="checkbox"]{
    width: 15px;
    height: 15px;
    font-size: 10px;
    margin-right: 5px;
}
.role-checkbox-ctr p{
    font-family: SF Pro Display, sans-serif;
    font-size: 15px;
    letter-spacing: 0.32px;
    color: #707070;
}
.role-error-msg{
    font-size: 13px;
    color: #890804;
    font-weight: bold;
    letter-spacing: 1px; 
    margin-top: 10px;
    height: 12px;
}
.role-name-ctr{
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}
.role-name-ctr label{
    font-family: inherit;
    font-size: 14px;
    margin-bottom: 2px;
    font-weight: bold;
}

.role-header-input input[type='text']{
    outline: 0;
    border: none;
    border-bottom:2px solid #448CCB;
    font-family: Open-Sans,sans-serif;
    font-size: 12px;
    color:#448CCB;
    padding: 5px;
}
.role-save-button{
    width: fit-content;
    margin-right: unset;
}
.role-form-description{
font-family: Open-Sans,sans-serif;
font-size: 12px;
font-weight: bold;
margin-top: 2%;
}
.role-form-description-details{
font: normal normal normal 13px/18px SF Pro Display;
color: #ACB1C0;
margin-top: 1%;
}
.mobile-app-permission,.web-app-permission{
    display: flex;
    margin-top: 2%;
    align-items: center;
}
.mobile-permission-card-container{
    align-items: center;
    width: 100%;
    display: flex;
}
.mobile-permission-card-container .group-management-card-label .choose-all-ctr{
    display: flex;
    align-items: center;
}
.icon{
   margin-top: 12px;
   padding:10px;
   flex-direction: column;
}
.roles-web-admin-permission-title{
    font-weight:bold;
    font-size:12px;
    margin-top: 2%;
    font-family: Open-Sans,sans-serif;
}
.roles-web-admin-permission-title-description{
    font: normal normal normal 13px/18px SF Pro Display;
    color: #ACB1C0;
    margin-top: 1%;
}



@media (max-width: 1025px){  
    .mobile-app-permission,.web-app-permission{
        flex-direction: column;
    }
    .role-card-ctr{
        width: 100%;
    }
   .mobile-app-permission .icon{
        flex-direction: row;
    }
    .web-app-permission .icon{
        flex-direction: row;
    }
    .mobile-app-permission  .icon svg{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        margin-bottom: 10px;
        cursor: pointer;
    }
    .web-app-permission  .icon svg{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        margin-bottom: 10px;
        cursor: pointer;
    }
    .role-header-input{
        flex-direction: column;
        width: 100%;
        align-items: unset;
    }
    .role-name-ctr{
        margin: 0px;
        margin-bottom: 20px;
        width: 50%;
    }
    .role-checkbox-ctr{
        margin: 0px;
        margin-bottom: 20px;
        width: 100%;

    }
}
@media (max-width: 960px){ 
    .roles-list .table-container .table-page-entry-container{
        font-size: 12px;
    }  
    .role-card-ctr{
        width: 100%;
    }
    
    .mobile-permission-card-container{
        align-items: center;
    }
    .creating-roles-conatiner{
        width: 60%;
    }
}
@media(max-width:768px){
    .roles-list .table-container .table-page-entry-container{
       font-size: 10px;
    } 
    .roles-listing-conatiner{
        width:37%;
    }
    .roles-list .table-container .table-inner-container{
        overflow: hidden;
    }
}
@media (min-width:769px) and (max-width:960px){
    .roles-listing-conatiner{
        width:35%
    }
}
@media (max-width: 640px){   
    .roles-container{
        flex-direction: column;
    }

    .roles-listing-conatiner {
        width: 100%;
    }
    
    .creating-roles-conatiner{
        width: 100%;
    }
}
@media (max-width: 480px){    
    .creating-roles-conatiner{
        width: 100%;
    }
    .role-header-input{
        width: 100%;
    }
}
@media (min-width:960px) and (max-width:1024px){
    .roles-listing-conatiner{
        width:31%
    }
}
