.mustering-event-history-container{
    height: 100%;
    width: 100%;
    overflow: auto;
}
.dateChooser {
    background:url("../../../assets/svg/calendar-alt-solid.svg") no-repeat;
    background-position:  right 8px top 40%;
}
.event-container{
    padding-top: 2%;
}
.event-container .status-bar{
    display: flex;
    width: 40%;
    justify-content: space-between;
    font-size: 13px;
} 
.event-date-select{
    width:95px ; 
    font-size:12px ;
     color: "#606060";
}
.event-select{
    width:100px ; 
    font-size:12px ;
     color: "#606060";
}

.padding-left-3{
    padding-left: 3.5%;
}

.mustering-event-history-title{
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
    font-weight: bold;
    padding-top: 10px;
    }
.mustering-event-history-selection-container{
    display:flex;
    padding-top:7px;
    margin-bottom: 10px;
}
.mustering-event-history-selection{
    padding-top:5px;
}
.mustering-event-history-selection-container .mustering-event-history-selection-option{
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
    font-weight: bold;
}
.mustering-event-history-view-details{
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
}
.mustering-event-history-selection{
    display: flex;
}
.mustering-event-history-date-and-event-selection .dateChooser {
    height: 20%;
    width:10%;
    font-size: 11px;
    font-family: Open-Sans,sans-serif;
    border-radius: 5px;
}
.mustering-event-history-date-and-event-selection .event-chooser{
    /* margin-left: 5px; */
}
.event-chooser{
    height: 20%;
    width:100%;
    /* font-size: 11px; */
    font-family: SF Pro Display, sans-serif;
    border-radius: 8px;
    border: 1px solid #707070;
}
.mustering-event-history-select-option{
    display: flex;
    padding-top: 8px;
    padding-bottom: 5px;
}
.mustering-event-history-select-option .mustering-event-history-date-label{
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
    font-weight: bold;
    width:12%;
}
.mustering-event-history-event-type-label{
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 3px;
}
.event-history-search-container {
    display: flex;
    margin-left:10px;
    align-items: flex-end;
}
.alert-history-search-btn{
    border: none;
    font-family: Open-Sans,sans-serif;
    height: 25px;
    font-size: 12px;
    border: 1px solid #707070;
    background-color: #707070;
    color: white;
    padding: 2px 10px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    cursor: pointer;
}
.event-history-btns{
    background: white;
    border:none;
    font-size: 12px;
    color: #9D9D9D;
    padding:5px;
    cursor: pointer;
    margin-left: 10px;
}
.mustering-event-history-date-and-event-selection{
    position: relative;
}
.request-service-date-picker-container{
	position: absolute;
    background:url("../../../assets/svg/popup.svg") 0% 0% no-repeat;
	z-index: 10;
	background-position: top center;
	width: 265px;
	height: 262px;
	background-size: cover;
	padding: 17px 10px 10px 10px;
	box-sizing: border-box;
}
.request-service-date{
    width:100%;
    border: 1px solid #707070;
    height: 25px;
    padding: 3px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 12px;
    background:url("../../../assets/svg/calendar-alt-solid.svg") no-repeat ;
    background-position:  right 8px top 40%;
}
.mustering-event-history-date-and-event-selection{
    width: 100%;
    align-items: flex-end;
}
.request-service-date::placeholder{
color:#606060;
padding-left: 3px;
}
.event-history-date-container{
    width: 200px;
}

.event-history-search-container .alert-user-search[type="text"]{
    background:none;
    border-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    padding-right: 5px;
    border: 1px solid #707070;

}
.event-history-search-container .alert-user-search[type="text"]:focus{
    outline: none;
}
.calender-margin{
    margin-left: -4%;
    margin-top: 6% !important 
}
@media only screen and (min-width:320px) and (max-width:480px){
     .mustering-event-history-selection-container{
         flex-direction: column;
     }
     .mustering-event-history-selection-container .mustering-event-history-selection-option{
        width:50%;
     }
    .mustering-event-history-selection-container .mustering-event-history-view-details{
        margin-top: 2%;
    }
    .mustering-event-history-select-option .mustering-event-history-date-label{
        width:48%;
    }
    .mustering-event-history-date-and-event-selection .dateChooser{
        width: 45%;
    }
    /* .mustering-event-history-date-and-event-selection .event-chooser{
        width: 50%;
    } */
    .mustering-event-history-date-and-event-selection .event-history-search{
        margin-top:3%;
        width: 97%;
    }
    .event-history-btns{
        /* margin-top: 3%; */
        /* width:50%; */
    }
    .mustering-event-history-inner-container .table-container .table-inner-container .table-body .table-data-row .table-data{      
     text-align: center;
    }
    .mustering-event-history-inner-container .table-container .table-inner-container .table-header .table-header-data{
      text-align: center;
    }
    /* .request-service-date{
        width:45%;
    } */
    .request-service-date-picker-container{
        width:265px;
        height: 282px;
   }
}
    @media only screen and (min-width:481px) and (max-width:768px){
    .mustering-event-history-selection-container .mustering-event-history-selection-option{
       
     }
    .mustering-event-history-select-option .mustering-event-history-date-label{
        width:18.5%;
    }
    .mustering-event-history-date-and-event-selection  .dateChooser {
        width:13%;
    }
    .request-service-date{
        width:100%;
    }
    .request-service-date-picker-container{
        width:265px;
        height: 270px;
   }
}
@media only screen and (min-width:769px) and (max-width:990px){
    .mustering-event-history-selection-container .mustering-event-history-selection-option{
        width:22%;
     }
     .mustering-event-history-select-option .mustering-event-history-date-label{
        width:17%;
    }
    .mustering-event-history-date-and-event-selection  .dateChooser {
        width:15%;
    }
    .request-service-date{
        /* width:15%; */
    }
    .request-service-date-picker-container{
         width: 265px;
         height: 278px;
         padding:14px 14px 14px 14px;
    }
 }
@media(min-width:1024px) {
   .mustering-event-history-selection-container .mustering-event-history-selection-option{
        width:15%;
     }
    .mustering-event-history-select-option .mustering-event-history-date-label{
        width:14%;
    }
    .mustering-event-history-selection  .dateChooser {
        width:11%;
    }
    .request-service-date-picker-container{
        width: 265px;
        height: 267px;
    }
    .request-service-date{
        /* width:12% */
    }
}
@media(min-width:1440px) {
    .mustering-event-history-title{
       font-size: 14px;
    }
    .mustering-event-history-selection-container{
        padding-top:12px;
    }
    .mustering-event-history-selection-option{
       font-size: 16px;
       width:15%;
    }
    .mustering-event-history-view-details{
        font-size: 14px;
    }
    .mustering-event-history-date-label{
        font-size: 14px;
        width:12%;
    }
    .mustering-event-history-event-type-label{
        font-size: 14px;
    }
    .mustering-event-history-selection  .dateChooser {
        width:10%;
        font-size: 13px;
    }
    .mustering-event-history-selection{
        padding-top:7px ;
    }
    .event-history-search::placeholder{
        padding-left: 34px;
    }
}
.event-history-event-type-container{
    width: 200px;
    margin-left: 10px;
}
.mustering-event-history-date-label{
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 3px;
}

@media (max-width: 640px){   
    .mustering-event-history-date-and-event-selection{
        flex-direction: column;
        align-items: flex-start;
    }
    .mustering-event-history-date-and-event-selection .event-chooser{
        margin: 0px;
    }
    .event-history-date-container{
        margin-bottom: 10px;
        
    }
    .event-history-event-type-container{
        margin-bottom: 10px;
        margin-left: 0px;
    }
    .event-history-search-container{
        margin-left: 0px;
        margin-bottom: 10px;
    }
}
