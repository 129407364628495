.manage-role-title{
    font-family: Open-Sans,sans-serif;
    font-size: 14px;
    letter-spacing: 1px;
    font-weight: bold;
    margin-bottom: 5px;
}
.manage-role-description{
    color: #ACB1C0;
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
    margin-bottom: 10px;
}
.role-card-container{
    display: flex;
    justify-content: center;
    font-family: Open-Sans,sans-serif;
}

.manage-role-cancel-btn, .manage-device-cancel-btn{
    background-color: white;
    color: black;
    margin-right: 25px;
}

.role-card-container .icon{
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.role-list-unselected-container-list, .role-list-selected-container-list{
    width: 45%;
}

@media (max-width: 640px){ 
    .role-card-container{
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
    }
    .role-list-unselected-container-list, .role-list-selected-container-list{
        width: 90%;
    }
    .role-card-container .icon{
        flex-direction: row;
    }
    .role-card-container .icon svg {
        transform: rotate(90deg);
        margin-bottom: 10px;
        cursor: pointer;
    }
}
