.create-view-alert-container {
    font-size: 13px;
    overflow: auto;
}
.alert-bold-label{
    font-weight: bold;
    margin: 5px 0px;
    font-size: 14px;
}
.alert-filter-container{
    display: flex;
    align-items: flex-end;
    margin-top: 10px;
}
.alert-select-filter{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.alert-select-filter select{
    border: 2px solid #bbbb;
}
.alert-search-filter input{
    border:1px solid #707070;
}
@media (max-width:640px) {
    .create-view-alert-container .table-data button{
        width: 100%;
        font-size: 10px;
    }
}
@media (max-width: 480px){   
    .alert-select-filter{
        width: 50%;
    }
    .create-view-alert-container .table-data{
        font-size: 11px;
    }
    
}
