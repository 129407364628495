.manage-device-detail-modal{
    width: 80%;
    padding:35px;
    height: auto;
}
.title-div{
    color: #448CCB;
    font-size: 16px;
    font-family: Poppins-bold;
    text-align: center;
}
.header-ctr{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    color: #333333;
    width: 100%;
    font-size: 14px;
    font-family: Poppins-regular;
    flex-flow: wrap;
    justify-content: space-between;
}
.overFlow{
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
    width: fit-content;
}
.id-div{
    min-width: 70px;
    font-weight: bold;
}
.name-div{
    min-width: 100px;
    font-weight: bold;
}
.version-div{
    min-width: 130px;
    font-weight: bold;
}
.battery-div{
    min-width: 95px;
    font-weight: bold;
}
.type-div{
    min-width: 90px;
    font-weight: bold;
    margin-right: 3px;
}
.device-model-icon-apple{
    margin-right: 5px;
    height: 16px;
}
.device-model-icon-android{
    height: 15px;
    margin-right: 6px;
}
.col1{
    width: fit-content;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
}
.column{
    display: flex;
    flex-direction: row;
}
.col2{
   width: fit-content;
   margin-right: 16px;
    display: flex;
    flex-direction: row;
}
.col3{
    width: fit-content;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
}
.col4{
    width: fit-content;
    margin-right: 16px;
    display: flex;
    flex-direction: row;
}
.col5{
    width: fit-content;
    display: flex;
    flex-direction: row;
}
.battery{
    width: 35px;
}
.user-and-device{
    display: flex;
    flex-direction: row;
    font-family: Poppins-regular;
    font-size: 14px;
    width: 100%;
    margin-top: 20px;
}
.green{
    background-color: #06BC37 !important
}
.dot {
    height: 12px;
    width: 12px;
    background-color: #FF0902;
    border-radius: 50%;
    display: inline-block;
    margin-top: 4px;
    margin-right: 5px;
  }
.user-div{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.device-div{
    width: 50%;
    display: flex;
    flex-direction: column;
}
.communication-status{
    font-family: Poppins-regular;
    font-size: 14px;
    margin-top: 25px;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.label{
    width: fit-content;
   color:#333333;
   opacity: 1;
   font-weight: bold;
   margin-right: 10px;
}
.footer-div{
    display: flex;
    width: 100%;
    justify-content: center;
    font-family: Poppins-regular;
    font-size: 14px;
    margin-top: 25px;
    align-items: center;
}
.close-div{
    color: black;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 25px;
}
.close-div:hover{
    cursor: pointer;
}


.left-footer-div{
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
.left-footer-div .cancel-button , .left-footer-div .save-button{
    font-family: Poppins-regular !important;
}
.download-div{
    display: flex;
    flex-direction: row;
    width: 21%;
    justify-content: flex-end;
    margin-right: 35px;
}
.download-div:hover{
    cursor: pointer;
}
.icon-div{
    width: 25px;
    height: 40px;
    margin-right: 5px
}
.requestLogs{
    display: flex;
    align-items: center;
    justify-content: center;
}
.rowDiv{
    display: flex;
    flex-direction: row;
    width: 130px;
}
.fetch-blocked{
    background-color: #2e4254;
    opacity: 0.5;
}
.req-blocked {
    opacity: 0.5;
}
.fetch-blocked:hover{
    cursor: not-allowed !important;
    background-color: #2e4254;;
}
.leftDiv{
    width: 25%;
}

.req-blocked:hover{
    cursor: not-allowed !important;
}
@media (min-width: 1910px) {
    .manage-device-detail-modal{
        max-height: 90%;
        height: fit-content;
    }
}
@media (min-width: 1440px) and (max-width: 1910px) {
    .manage-device-detail-modal{
        height: fit-content;
        max-height: 90%;
        min-width: 80%;
        width: fit-content;
    }
}
@media (min-width: 1240px) and (max-width: 1440px) {
    .manage-device-detail-modal{
        height: fit-content;
        max-height: 90%;
        width: 90%;
    }
}
@media (min-width: 1025px) and (max-width: 1240px) {
    .manage-device-detail-modal{
        height: fit-content;
        width: 90%;
    }
}
@media (min-width:950px) and (max-width: 1025px) {
    .manage-device-detail-modal{
        height: fit-content;
        max-height: 90%;
        width: 90%;
        padding: 30px;
    }
    .communication-status{margin-top: 20px;}
    .footer-div{
        margin-top: 20px;
    }
}
@media (min-width:800px) and (max-width: 950px) {
    .manage-device-detail-modal{
        max-height: 90%;
        width: 96%;
        padding: 30px;
        height: fit-content;
    }
    .header-ctr , .user-and-device, .communication-status{
        font-size: 13px;
    }
}
@media (min-width: 500px) and (max-width: 800px) {
    .manage-device-detail-modal{
        height: 90%;
        width: 90%;
    }
    .header-ctr{
        justify-content: start;
    }
    .user-and-device{
        flex-direction: column;
    }
    .device-div{
        margin-top: 20px;
        width: 100%;
    }
    .user-div{
        width: 100%;
    }
    .download-div{
        width: 40%;
    }
}
@media (min-width: 300px) and (max-width: 500px) {
    .header-ctr{
        flex-direction: column;
    }
    .col1 , .col2 , .col3 , .col4 , .col5{
        width: 274px;
    }
    .overFlow{
        width: fit-content;
    }
    .id-div{
        width: 72px;
    }
    .name-div{
        width: 101px;
    }
    .user-and-device{
        flex-direction: column;
    }
    .version-div{
        width: 134px;
    }
    .device-div{
        margin-top: 20px;
        width: 100%;
    }
    .user-div{
        width: 100%;
    }.manage-device-detail-modal{
        font-size: 6px;
        height: 90%;
        width: 95%;
    }
    .footer-div{
        flex-direction: column;
        align-items: center;
        width: 100%;
    }
    .left-footer-div{
        width: 100%;
        justify-content: center;
    }
    .download-div{
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
    }
    .column{
        flex-direction: column;
    }
}
