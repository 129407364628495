.specific-group-management-container{
    height: 100%;
    /* overflow: auto; */
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
}
.specific-group-management-title{
    font-weight: bold;
    
}
.specific-group-management-btn-field{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    margin-bottom: 10px;
}
.specific-group-management-btn-field button{
    width: 160px ;
    border-radius: 4px;
    font-family: SF Pro Display, sans-serif;
    font-size: 14px;
    background-color:#2c4458 ;
}
.specific-group-management-btn-field button:last-child{
    background-color: transparent;
    color: black;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
}
.specific-group-management-btn-field button:last-child:only-child {
    width: 96%;
    height: 30px;
    justify-content: flex-end;
}
.specific-group-management-btn-field button:last-child svg{
    font-size: 20px;
    margin-right: 5px;
}
.specific-grouup-management-filter-container{
    display: flex;
    align-items: flex-end;
    margin-bottom: 10px;
}
.specific-grouup-management-selection-filter{
    display: flex;
    flex-direction: column;
    width: 20%;
}
.specific-grouup-management-selection-filter .specific-group-management-title{
    margin-bottom: 7px;
}
/* .specific-group-management-table-container{
    height: 45%;
} */
.back-to-group-btn button{
    background-color:#2c4458 ;
    color:white;
    border: none;
    padding: 7px 30px;
    font-family: inherit;
    border-radius: 4px;
}
.specific-group-management-table-container .table-select{
    padding-top: 0px;
    padding-bottom: 0px;
    min-height: 20px
}
.specific-group-management-table-container .table-paginate-inner-container{
    padding: 0px;
}
.specific-group-management-service-allotement-container{
    display: flex;
    margin-top:15px;
    flex-direction: column;
}
.specific-group-management-service-allotement-container .service-allotement-title{
    margin-right: 10px;
    font-weight: bold;
}
.service-allotement-checkbox-container input[type="checkbox"]{
    width: 18px;
    height: 18px;
}
.specific-group-service-ctr{
    display: flex;
    margin: 7px 0px;
}
.add-additional-ctr{
    color:#438AFE;
}
.service-allotement-checkbox-container{
    display: flex;
    align-items: center;
    margin: 7px 0px;

}
.service-allotement-checkbox-container span{
    margin-right: 10px;
}
@media only screen and (max-width:480px) {
    .specific-grouup-management-selection-filter{
        width: 40%;
    }
}
