.mustering-container{
    font-family: Open-Sans,sans-serif;
    font-size: 14px;
    overflow: auto;
}
.mustering-container input[type="checkbox"]{
    width: 18px;
    height: 18px;
}
.mustering-container .status-bar{
    display: flex;
}

.mustering-container .status-bar div{
    margin-right: 7px;
}
.mustering-container input{
    height: 25px;
}
.mustering-container .table-data{
    font-size: 11px;
}
.display-flex{
display: flex;
/* align-items: baseline; */
}
.space-between{
    justify-content:space-between;
    margin:10px 0px;
    align-items: baseline;
}
.space-between div{
    margin:3px 0px;
}
.mustering-search-ctr{
    display: flex;
    align-items: baseline;
}
.mustering-title{
    font-weight: bold;
}
.col-9{
    width:9%;
}
.col-30{
    width:30%
}
.col-40{
    /* width: 40%; */
    margin-top:-5px
}
.search-label{
    font-size: 14px;
    margin-top: 9px;
    font-weight: bold;
}

.margin-left-10{
   margin-left:10%;
   margin-top:7%
}
.margin-left-2{
    margin-left:0%;
}
.searching input[type="text"] {
    
    color: black;
    border: 2px solid #bbbb;
    font-size: 12px;
    border-radius: 5px;
    padding:3px 5px;
    margin-top: 5%;
    width:100%;
    background: url('../../../assets/svg/navIcons_search.svg') no-repeat right 3% center;
    background-size: 16px;
    padding-right: 30px;
}
.mustering-container .table-title-container{
    font-size: 11px;
    font-weight: bold;
}
.mustering-container .table-entry-container{
    font-size: 11px;
   color: #7C7C7C;
}
/* .current-result{
   font-size: 11px;
 
} */
.vertical-line{
margin:0% 12% 12% 12% ;
border-left: 2px solid #7C7A7A38;
height: 22px;
}
.report-line{
 /* margin: 1%; */
 border-left: 2px solid #7C7A7A38;
 height: 22px;
}
.event-result{
    display: flex;
    justify-content:space-between;
    font-size: 13px;
}
.col-22{
    width:20.5%;
}
.col-80{
    width:80%;
    font-weight: bold;
}
.muster-responsive{
    display: none;
}
.margin-5{
    margin-top:2%;
}
.display-flex  select{
    height: 20%;
    width:20%;
    font-size: 11px;
    font-family: SF Pro Display, sans-serif;
    border-radius: 5px;
}
.active-mustering-alarm-triggered-by{
    font-family: Open-Sans,sans-serif;
    font-size:14px;
}
.active-mustering-alarm-triggered-by-name{
    font-weight: bold;
}
.active-mustering-details-ctr{
    flex-wrap: wrap;
    width: 97%;
}
.space-between .active-mustering-title{
    margin-bottom: 10px;
}
.filter-reset-container{
    color: #9D9D9D;
    align-items: center;
}
.margin-top-2{
    margin-top: 5px;
}
.margin-top-3{
    margin-top: 3%;
}
.current-result-1 div{
    
    font-weight: bold;
}
.application-table-container .table-header-data{
    font-size: 12px;
    font-weight: bold;
    color: #666666;
}
.mustering-container .table-entry-container{
    margin-right: 10px;
}
.mustering-container .table-entry-container select{
    margin: 0px 10px;
    font-size: 11px;
    width: 50px;
}
.mustering-container .table-paginate-container{
    font-size: 11px;
}
.mustering-container .current-result-2{
    border-right: 2px solid rgb(192, 192, 192);
    padding-right: 8px;
}
.clear-btns{
    background: white;
    border:none;
    font-size: 12px;
    color: #9D9D9D;  
    padding: 2px 14px;
    height: 20px;
    cursor: pointer;
    font-size: 13px;
    border-right:2px solid #CCC;
}
.mustering-container .refresh-btn{
    border: none;
}
.current-result{
    display: flex;
    align-items: center;
    height: 20px;
}
.mustering-container .table-data img{
    border-radius: 50%;
    height: 30px;
}
.refresh-btn{
    background: white;
    border:none;
    font-size: 12px;
    color: #9D9D9D;  
    padding: 2px 14px;
    height: 20px;
    cursor: pointer;
    font-size: 13px;
}
.current-result-title{
    display:none;
}
.count-bold{
    font-weight: bold;
}
.back-to-mustering-btn{
    cursor: pointer;
    border: none;
    background: none;
    letter-spacing: 0.5px;
    display: flex;
    align-items: center;
    font: inherit;
    font-weight: bold;
}
.back-to-mustering{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-right: 5px;
}
.back-to-mustering svg{
    margin-right: 5px;
}
@media (min-width:1440px){
    .application-table-container .table-header-data{
        font-size: 14px;
    }
    .mustering-container .table-title-container,.mustering-container .table-entry-container{
        font-size: 14px;
    }
    .mustering-container{
        font-size: 14px;
    }
    .mustering-title{
        font-size: 14px;
    }
    .search-label{
        font-size: 14px;
    }
    .display-flex  select{
        font-size: 14px;
    }
    .mustering-container .table-data{
        font-size: 14px;
    }
    .application-table-container .table-header-data{
        font-size: 15px;
    }
    .mustering-container .status-bar{
        /* width: 400px; */
        padding-right: 5px;
    }
    .col-40{
        width:30%
    }
}
/* @media (max-width:481px) {
    .display-flex {
        justify-content: center;
        align-items: center;  
    }
    .margin-top-3{
        margin-top:80%;
    }
    .display-flex .col-22{
        width: 50%;
        margin-left: -20%;
        padding: 5%;
    }
 
} */
@media(max-width:600px) {
 .display-flex{
     flex-direction: column;
 }
 .mustering-title{
     text-align: left;
 }
 .margin-left-10{
     margin-left: 0%;
 }
 .col-40{
     width:100%;
     margin-top:0px
 }
 .muster-responsive{
     display: block;
     padding:5px;
 }
 .not-responsive{
     display: none;
 }
 .display-flex select{ 
   width:100%
 }
 .mustering-container .table-options-container {
    padding:5px;
  }
  .col-80{
      width:100%
  }
  .mustering-container .status-bar{
      /* width:100%; */
      flex-wrap: wrap;
     }
  .mustering-container .status-bar div{
      font-size: 10px;
      white-space: nowrap;
  }
  .filter-reset-container{
    margin-top: 10px;
    width: 100%;
    justify-content: center;
  
  }
}
@media(max-width: 768px){
.searching input[type="text"]{
    width:90%;
  }
  .col-22{
      width: 19%;
  }
}
@media only screen and (min-width:769px) and (max-width:990px){
    .searching input[type="text"] {
        width:87%;
    }
    .col-80{
        width:100%
    }
    .col-40{
        width:100%
    }
}
@media (min-width:1440px){
.margin-top-3{
    margin-top: 10%;
  }
  .margin-left-2{
      margin-left: 1.4%;
  }
  .col-22{
      width:19%;
  }
}
@media (max-width: 1025px){   
    .space-between{
        flex-direction: column;
    }
    .mustering-search-section{
        width: auto;
    }
    .col-40{
        width:40%
    }
}
@media (max-width: 480px){ 
    .mustering-container .status-bar{
        width: 100%;
    }
    .mustering-container .status-bar  .current-result-1{
        display: none;
     }
    .mustering-container .status-bar div{
        margin-right:7px;
    }
    .current-result-title{
        display:block;
        font-weight: bold;
    }
    .col-40{
        width:100%
    }
}
