.alert-setup-container{
    font-family: Open-Sans,sans-serif;
    font-size: 14px;
    overflow: auto;
}
.alert-title{
    font-weight: bold;
    margin: 10px 0px;
}
.alert-configuration-ctr{
    margin-left: 10px;
}
.alert-display-sort-ctr, .alert-display-radio-ctr{
    display: flex;
    align-items: flex-start;
}
.alert-display-radio-ctr{
    margin-right: 20px;
}
.alert-display-radio-ctr input[type=radio]{
    margin-left:5px;
    border: 2px solid #1198ED;
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 18px;
    height: 18px;
}

.alert-display-radio-ctr input[type="radio"]::after{
    border: 5px solid #1198ED;
}
.alert-display-radio-ctr input[type="radio"]:checked{
    border: 5px solid #1198ED;
}
.alert-setup-container .alert-setup-btn-ctr{
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.alert-display-radio-ctr label{
    cursor: pointer;
    font-weight: bold;
    letter-spacing: 1px;
    font-size: 12px;
}
.alert-order-hint{
    font-size: 12px;
    color: #333333;
    margin: 4px 0px;
}
.alert-sub-title{
    font-size: 12px;
}

.toneLibTitle{
    margin-left: 10px;
}

.defaultAlertToneContainer{
    display: flex;
    width: 50%;
    flex-direction: column;
}
.toneLibrayContainer{
    width: 40%;
}
.defaultAlertToneTitleAndText{
    display: flex;
    flex-direction: column;
    font-family: Open-Sans,sans-serif;
    font-size: 12px;
}
.alertToneTitle{
    font-weight: bold;
    margin-top: 10px;
    font-family: Open-Sans, sans-serif;
    font-size: 12px;
}

.toneDescription{
    font-family: Open-Sans, sans-serif;
    font-size: 12px;
    width: 68%;
}
.alertToneText{
    margin-top: 10px;
}
.audioCustomeContainer{
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    font-family: Open-Sans,sans-serif;
    font-size: 12px;
    width: 100%;
}
.audioTableTitle{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 25px;
}
.audioTableCol1{
    width: 20%;
    display: flex;
    flex-direction: row;
    font-weight: bold;
    align-items: center;
}
.audioTableCol2{
    margin-right: 10px;
    display: flex;
    align-items: center;
    width: 30%;
}
.audioTableCol3{
    width: 40%;
    display: flex;
    align-items: center;
}
.audioTableCol4{
    width: fit-content;
    display: flex;
    flex-direction: row;
    color: #448CCB;
    align-items: center;
}
.audioTableCol4{
    margin-left: 10px;
}
.audioTableCol4:hover{
    cursor: pointer;
}
.generalAlertContainer, .musteringAlertContainer, .quickMessageContainer, .alarmClearedContainer, .clearAlertRemainderContainer{
    margin-top: 5px;
    display: flex;
    flex-direction: row;
    height: 50px;
    width: 100%;
}
.question{
    -webkit-text-fill-color: #FF0000;
    margin-left: 7px;
}
.col2Container{
    height: 30px;
    width: 100%;
}
.upload-icon{
    margin-left: 5px;
    margin-right: 10px;
}
.upload-text{
    font-family:Open-Sans,sans-serif;
    font-size: 12px;
}
.modalAlign{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    background: rgba(49,49,49,0.8);
    display: flex;
    align-items: center;
    justify-content: center;
}
.preview-tone{
    width: 100%;
    border-style: solid;
    border-radius: 20px;
    border-width: thin;
    box-shadow: 0px 5px 8px #00000029;
    height: 40px;
}
.notifyOnServerDisconnectCont{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    font-family: Open-Sans,sans-serif;
    font-size: 12px;
}
.enable-severDisNotify-checkbox-ctr{
    font-family:  Open-Sans,sans-serif ;
    display: flex;
    flex-direction: row;
}
.enable-severDisNotify-label{
    cursor: pointer;
    font-size: 12px;
    font-weight: bold;
    letter-spacing: 1px;
}
.enable-severDisNotify-checkbox{
    margin-left: 5px;
    border: 2px solid black;
    width: 30px;
    height: 17px;
    padding: 7px;
}
.alertType{
    display: flex;
    justify-content: center;
    align-items: center;
}
.question{
    display: flex;
    justify-content: center;
    align-items: center;
}
.enableServerDisNotifyDescription{
    font-size: 12px;
    margin-top: 7px;
}
.spanPreview{
    display: none;
}
.serverUploadToneCtr{
    display: flex;
    flex-direction: column;
}
.spanToneLibrary{
    display: none;
}
.remainder-interval-cont{
    display: flex;
    flex-direction: row;
    margin-top:30px ;
    font-family: Open-Sans,sans-serif;
    font-size: 12px;
    width: 100%;
}
.remainder-interval{
    font-weight: bold;
    margin-top: 10px;
}
.interval-cont{
    display: flex;
    flex-direction: row;
    margin-top: 10px;
    align-items: center;
}
.interval-show-cont{
    cursor: not-allowed;
    opacity: 0.5;
}
.interval-input{
    width: 80px;
    height: 30px;
    border-radius: 3px;
    margin-left: 6px;
    margin-right: 6px;
    box-shadow: 0px 5px 8px #00000029;
    padding-left: 10px;
    padding-right: 10px;
    border: 1px solid #333333;
}
.remainder-interval-cont input[type="checkbox"]{
    border: 2px solid #448CCB;
    width: 20px;
    height: 20px;
}
.notifyOnServerDisconnectCont input[type="checkbox"]{
    border: 2px solid #448CCB;
    width: 20px;
    height: 20px;
}
.remainder-interval-cont input[type="checkbox"]:checked{
    background-color: #448CCB;
    color: white;
}
.notifyOnServerDisconnectCont input[type="checkbox"]:checked{
    background-color: #448CCB;
    color: white;
}
.escalationColorsCont{
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    font-family: Open-Sans,sans-serif;
    font-size: 12px;
}
.escalationTitle{
    font-weight: bold;
}
.escalationContent{
    display: flex;
    flex-direction: row;
    margin-top: 20px;
}
.escaltionRightSide{
    display: flex;
    flex-direction: row;
    margin-left: 40px;
}
.after{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
}
.interval-input-escalation{
    width: 50px;
    height: 30px;
    box-shadow: 0px 5px 8px #00000029;
    border: 1px solid #333333;
    border-radius: 4px;
    padding-left: 10px;
    padding-right: 10px;
}
.escaltionLeftSide{
    display: flex;
    flex-direction: row;
}.escaltionRightSide{
    display: flex;
    justify-content: center;
    align-items: center;
}
.useColor{
    margin-right: 15px;
}
.minutes-escalation{
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.alertRemainderIntervalCont, .clearRemainderIntervalCont, .checkinRemainderIntervalCont{
    width: 33%;
}
.add-level-button{
    background-color: #304455;
    font-size: 13px;
    border-radius: 5px;
    margin-left: 5px;
    margin-bottom: 0px;
    width: 110px;
    height: 50px;

   /* position: absolute;*/

    -webkit-text-fill-color: white;
    font-family: Open-Sans,sans-serif;
    margin-top: 20px;
}
.color-picker{
    display: flex;
    flex-direction: column;
}
.colors{
    max-height: 250px;
    overflow-y: auto;
    z-index: 1;
    position: absolute;
}
.iconDiv{
    margin-left: 20px;
}
.iconDiv:hover{
    cursor: pointer;
}
.tooltip-container {
    position: relative;
}

.custom-tooltip {
    visibility: hidden;
    width: 180px;
    background-color: #333;
    color: #fff;
    text-align: center;
    padding: 10px;
    border-radius: 5px;
    position: absolute;
    z-index: 1;
    bottom: 111%; /* Position above the element */
    left: 10px;
    transform: translateX(-50%);
    opacity: 0;
    transition: opacity 0.3s;
    font-family: Open-Sans,sans-serif;
    font-size: 12px;
}

.custom-tooltip::after {
    content: "";
    position: absolute;
    top: 100%; /* At the bottom of the tooltip */
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #333 transparent transparent transparent;
}

.tooltip-container:hover .custom-tooltip {
    visibility: visible;
    opacity: 1;
}

.tooltip-container:hover {
    cursor: pointer;
}
.color-selector {
    display: flex;
    align-items: center;
    margin: 0 auto;
    border: 2px solid #333232;
    border-radius: 3px;
    position: relative;
    padding: 6px 32px 6px 10px;
    font-family: verdana;
    background: white;
    width: 238px;
    height: 30px;

    &::after {
      content: '';
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: black transparent transparent transparent;
      position: absolute;
      right: 10px;
    }

    .circle {
      display: inline-block;
      width :20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #eee;
      margin-right: 10px;
    }
    .span {
        display: inline-block;
        vertical-align: middle;
       }
    .hidden {
      position: absolute;
      left: 0;
      opacity: 0;

    }


  }
  .toneContainer{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
.toneLibrary{
    margin-left: 10px;
    display: flex;
    flex-direction: row;
    margin-top: 7px;
    align-items: flex-start;
}
  .cont{
    width: 200px;
  }

  @media screen and (min-width: 800px) and (max-width: 1400px) {
    .defaultAlertToneContainer{
        width: 55%;
    }
    .toneLibrayContainer{
        width: 45%;
    }
  }
  
  @media screen and (min-width: 300px) and (max-width: 800px) {
   .toneContainer{
         flex-direction: column;
   }
   .defaultAlertToneContainer{
    width: 100%;
    }
    .toneLibrayContainer{
        width: 100%;
    }
  }
  @media screen and (max-width: 800px) {
    .generalAlertContainer, .musteringAlertContainer, .quickMessageContainer, .alarmClearedContainer, .clearAlertRemainderContainer{
        flex-direction: column;
        width: 100%;
        height: unset;
        margin-top: 20px;
    }
    .toneLibTitle{
        margin-left: 0px;
        margin-top: 20px!important;
    }
    .toneLibrary{
        margin-left: 0px;
        margin-bottom: 5px;
        width: 100%;
    }
    .toneDescription{
        width: 52%;
    }
    .serverNotify{
        margin-top: 1px !important;
    }
    .audioTableTitle{
        display: none;
    }
    .spanToneLibrary, .spanPreview{
        display: block;
        width: 20%;
    }
    .audioTableCol1, .audioTableCol2, .audioTableCol3, .audioTableCol4{
        width: 100%;
        margin-top: 10px;
    }
    .uploadToneBtn{
        width: 48% !important;
    }
    .col2Container{
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    .audioTableCol3{
        height: unset;
    }
    .preview-tone{
        height: 40px;
        max-width: 250px;
    }
    .cont{
        height: 30px;
        max-width: 250px;
    }
    .dropDownContainer{
        height: -webkit-fill-available;
    }
    .audioTableCol4{
        margin-left: 0px;
    }
    .remainder-interval-cont{
        flex-direction: column;
        width: 100%;
        margin-top: 7px !important;
    }
    .alertRemainderIntervalCont, .clearRemainderIntervalCont, .checkinRemainderIntervalCont{
        margin-top: 23px;
    }
    .audioCustomeContainer{
        margin-top: unset;
    }
    .alertRemainderIntervalCont, .clearRemainderIntervalCont, .checkinRemainderIntervalCont{
        width: 100%;
    }
    .tone-lib-height{
        height: 130px;
    }
    .custom-tooltip {
        bottom: 117%;
    }
  }
