* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

}

.model-content {
  overflow: auto;
  width: 100%;
  max-height: 100%;
  height: 100%;
}

.heading {
  margin-bottom: 1%;
  font-size: 16px;
}

.add-event-section {
  width: 37%;
  padding: 0.5%;
}

.event-field-ctr {
  display: flex;
  flex-direction: column;
  font-family: Open-Sans, sans-serif;
  margin-bottom: 3%;
}

.event-input-field {
  margin: 1% 0% 0% 1%;
  width: 85%;
  border: 1px solid #B1B4AA;
  height: 30px;
  box-shadow: 0px 0px 1px #707070;
  padding: 0% 2% 0% 2%;
  font-family: Open-Sans, sans-serif;
  border-radius: 4px;
}

.warn-msg {
  border: 1px solid #890804 !important;
} 

.warn-msg input:focus {
  outline: none;
}

.description {
  height: 107px;
  padding: 2%;
  resize: none;
  color: black;
  /* font-weight: bold; */
}

.description::placeholder {
  position: absolute;
  top: 0;
  left: 0;
  padding: 2%;
}

.event-field-ctr input::placeholder {
  font-weight: bold;
}

.event-field-ctr input[type="text"] {
  color: black;
}

textarea::placeholder {
  font-weight: bold !important;
}

.modal-content-container-add-events-ctr {
  background: #ffffff;
  border-radius: 10px;
  width: 85%;
  height: 89%;
  padding: 15px;
}

.instruction {
  font-family: Open-Sans, sans-serif;
  font-size: 13px;
  color: #555555;
  margin: 0% 0% 3% 0%;
}

.event-date-ctr {
  display: flex;
  flex-direction: row;
  font-family: Open-Sans, sans-serif;
  font-weight: bold;
  font-size: 14px;
  margin-left: 2% !important;
  margin-bottom: 3%;
  position: relative;
}

.eventdate {
  margin-top: 8%;
  width: 269px !important;
  height: 286px !important;

}

.event-register-radio-ctr {
  width: 80%;
  border: none;
  font-family: Open-Sans, sans-serif;
  font-weight: bold;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.calenderIcon {
  width: 13%;
}

.copy-icon {
  width: 6%;
  cursor: pointer;
  z-index: 1;
}



.date-field {
  display: flex;
  flex-direction: row;
  width: 40%;
  padding-left: 2%;
  border: 1px solid #B1B4AA;
  border-radius: 4px;
  height: 30px;
  cursor: pointer;
}
.date-field input::placeholder{
  font-weight: bold;
}
.end-date {
  margin-left: 3% !important;
}

.event-time-pickers {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-family: Open-Sans, sans-serif;
  font-weight: bold;
  font-size: 14px;
  margin-left: 2% !important;
  margin-bottom: 3%;
  width: 90%;

}

.start-time-picker {
  margin-right: 0%;
  width: 50%;
}

.end-time-picker {
  margin-left: -3%;
  width: 50%;
}


.event-registration-ctr {
  display: flex;
  flex-direction: row;
  width: 50%;
  margin-left: -5%;
  margin-bottom: 3%;
}

.event-register-radio {
  color: #333333;
  font-size: 14px;
}

.event-url-ctr {
  display: flex;
  align-items: center;
}

.event-url-ctr img {
  margin-left: 2%;
  cursor: pointer;
}

.url-label {
  font-size: 14px;
  font-family: Open-Sans, sans-serif;
  color: #555555;
}

/*QR-css*/
.qr-url {
  width: 69%;
  margin-left: 2%;
  font-weight: normal !important;
}

.qr {
  width: 77%;
  margin-bottom: 50%;
}

.qr-code {
  height: 30% !important;
  width: 30% !important;
  margin-right: 3%;
  margin-left: 3%;
}

.qr-div {
  display: flex;
  align-items: flex-start;
  margin-top: 8%;
}

/* error-msg */
.error-msg {
  color: #890804;
  position: absolute;
  top: -30px;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

/* buttons */
.manage-events-btn {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  position: relative;
}

.event-date-input {
  width: 80%;
  border: none;
  font-family: Open-Sans, sans-serif;
  /* font-weight: bold; */
  cursor: pointer;
  color: black;
}

.event-date-input:focus {
  outline: none;
}

.limit-msg {
  margin-top: 2%;
  text-align: right;
  margin-right: 15%;
  color: #555555;
  font-family: Open Sans, sans-serif;
  font-size: 12px;
}

.form-div {
  display: flex;
  flex-direction: row;
  height: 654px;
  justify-content: space-between;
}

.galleryDiv {
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 0.5%;
}

.imageContainer {
  height: 50%;
}

.docsContainer {
  height: 30%;
}

.event-title {
  font-family: Open-Sans, sans-serif;
  font-weight: bold;
  font-size: 14px;
  margin-bottom: 2%;
}

.add-files-icon img {
  height: 120px;
  width: 98%;
  padding: 2% 2% 2% 0%;
  margin-right: 2%;
  margin-left: -3%;
  cursor: pointer;
}

.image-content-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 1fr));
  grid-template-rows: repeat(auto-fill, minmax(50%, 3fr));
  height: 79%;
  width: 100%;
}

.document-content-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(25%, 0fr));
  grid-template-rows: repeat(auto-fill, minmax(50%, 3fr));
  height: 70%;
}

.previewimgGallery {
  height: 120px;
  width: 98%;
  padding: 9% 5% 9% 0%;
}

.pdf {
  width: 60%;
  margin-left: 20%;
}

.word {
  width: 49%;
  margin-left: 18%;
}

.middle {
  justify-content: space-around !important;
  margin-top: 1%;
}

.preview p {
  margin-top: -10px;
  color: #333333;
  font-family: 'Open-Sans';
  font-size: 14px;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 96%;
}

.icon-container svg {
  width: 17px;
  height: 19px;
}

.deleteImageIcon img {
  width: 17px;
  height: 15px;
}

.gallery-icon-div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10%;
  position: absolute;
  top: 19px;
  right: 0px;
  color: white;
  width: 36px;
  cursor: pointer;
}

.dark {
  color: #555555;
  margin-left: 3%;
}

.event-file-import {
  width: 40%;
  z-index: 901;
  padding: 15px 15px 15px 25px;
}

.copy-message {
  position: relative;
  top: 10%;
  left: 15%;
  transform: translate(-50%, -50%);
  background-color: black;
  color: white;
  padding: 2px 9px;
  border-radius: 5px;
  z-index: 9999;
  opacity: 0.9;
}
.img-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3%;
}

.modal-indexed{
  z-index: 901;
}
@media (max-width: 330px){   
  
    .add-files-icon img {
      height: 66px;
    }

    .copy-icon {
      display: none;
    }

    .download {
      display: block !important;
    }
    .qr-url {
      width: 86% !important;
    }
}
@media (max-width: 960px){   
  .previewimgGallery {
      height: 71px;
      width: 98%;
      padding: 2% 5% 9% 0%;
    }
  
    .gallery-icon-div {
      top: 4px
    }
  
    .add-files-icon img {
      height: 66px;
    }

    .imageContainer {
      height: 33%;
    }

    .modal-content-container-add-events-ctr{
      height: 71%;
    }

    .eventdate {
      /* background:url("../../../assets/svg/popup.svg") 0% 0% no-repeat; */
      margin-left: -14% !important;
    }
  
       .flip {
         margin-left: -21% !important;
      }

    .copy-icon,.table-copy-icon {
        display: none;
    }

    .download {
      display: block !important;
    }
}
@media (max-width: 500px) {
  .form-div {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: auto;
  }

  .copy-icon,.table-copy-icon {
    display: none;
  }

  .download {
    display: block !important;
  }

  .qr-url {
    width: 86% !important;
  }

  .add-event-section {
    width: 100%;
  }

  .event-input-field {
    width: 96%;
  }

  .date-field {
    width: 43.5%;
  }

  .event-time-pickers {
    width: 98%;
  }

  .limit-msg {
    margin-right: 3%;
  }

  .eventdate {
    margin-left: -8% !important;
  }

     .flip {
       margin-left: -21% !important;
    }

  .image-content-container,
  .document-content-container {
    grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
    grid-template-rows: repeat(auto-fill, minmax(50%, 1fr));
    height: 245px;
  }

    

  .imageContainer{
    height: 49%;
    margin-left: 1%;
  }
  .galleryDiv {
    width: 97%;
  }
  .middle {
    flex-direction: row;
    margin-bottom: 11%;
  }
    .previewimgGallery {
      height: 131px;
      width: 90%;
      padding: 10px 0px 23px 0px;
    }
    .add-files-icon img{
      height: 84%;
      margin-top: 5%;
    }
    .gallery-icon-div {
      top: 12px;
      right: 2px;
    }
    .preview p {
      margin-top: -26px;
      margin-left: -2%;
    }
  .event-file-import {
    width: 90%;
  }

  .error-msg{
    position: unset;
    margin-bottom: 3%;
  }

}

/* padding: 4% 5% 5% 0%; */
@media (min-width: 1440px){   
  .previewimgGallery {
    padding: 4% 5% 5% 0%;
    width: 85%;
  }

  .event-file-import {
    width: 26%;
  }

  .preview p {
    margin-top: -14px;
    margin-left: -6%;
  }

  .gallery-icon-div {
    right: 25px;
  }

  .qr-code {
    padding: 2%;
    margin-top: -3%;

  }

  .add-files-icon img {
    margin-left: -18px;
  }
}
@media (min-width: 1990px){   
  .previewimgGallery {
    padding:2% 5% 2% 0%;
    width: 85%;
  }

  .form-div {
    height: 761px;
  }
  .preview p {
    margin-top: -6px;
    margin-left: -6%;
  }

  .gallery-icon-div {
    right: 25px;
  }

  .qr-code {
    padding: 2%;
    margin-top: -3%;

  }

  .add-files-icon img {
    margin-left: -18px;
  }
}
