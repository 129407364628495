.property-details-container{
    font-family: Poppins-regular, sans-serif;
    font-size: 14px;
    font-weight:bold;
    max-height: 100%;
    overflow: auto;
}
.property-detail-section{
    width: 40%;
    display: flex;
    flex-direction: column;
}
.property-detail-card-section{
    width: 100%;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 20px #00000034;
    padding: 10px;
    border-radius:5px;
}
.zip-country-ctr{
    display:flex;
    justify-content: space-between;
    flex-wrap: wrap;
}
.property-detail-card-section label{
    font-size: 14px;
}
.property-detail-card-section .invalid{
    border: 1.5px solid #890804 !important;
}
.property-detail-card-section input{
    box-shadow: 0px 5px 8px #00000029;
    border: 1px solid #333333;
    border-radius: 4px;
    padding: 3px;
    width: 100%;
    display: block;
    margin: 5px 0px;
    margin-bottom: 10px;
  
}
.Address-title{
    color: #448CCB;
}
.street-suit-ctr{
    display: flex;
    margin-bottom: 10px;
}
.street-input{
    width: 90%;
    margin-right: 20px;
}
.city-state-zip-ctr{
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 10px;
  
}
.property-detail-card-section .input-error-message{
    font-family: 'Open-Sans';
    letter-spacing: 1px;
}
.city-state-zip-ctr .input-error-message{
    margin-top: 10px;
}
.street-2-ctr{
    margin-bottom: 20px;
}
.city-state-zip-ctr label{
    word-wrap: break-word;
}
.city-state-zip-ctr input{
    margin-bottom: 0px;
}
.property-ctr , .address-ctr{
    width: 95%;
}
.property-ctr{
    margin-bottom: 5px;
}
.no-change-error{
    margin: 10px 0px;
    text-align: center;
    margin-top: 0px;
}
.state-province-select-ctr select{
    width: 80%;
    box-shadow: 0px 5px 8px #00000029;
    border: 1px solid #333333;
    border-radius: 4px;
    padding: 3px;
    margin-top: 3px;
}
.city-input-ctr{
    width: 48%;
}
.state-province-select-ctr{
    width: 48%;
}
.zipcode-input-ctr{
    width: 48%;
}
.country-select-ctr{
    
    flex-direction: column;
    width: 48%;
}

.country-select-ctr select{
    box-shadow: 0px 5px 8px #00000029;
    border: 1px solid #333333;
    border-radius: 4px;
}
.property-btn-ctr{
    display: flex;
    justify-content: center;
}
@media (min-width: 1440px){   
    .property-detail-section{
        width: 30%;
    }
}
@media (max-width: 960px){   
    .property-detail-section{
        width: 55%;
    }
}
@media (max-width: 640px){   
    .property-detail-section{
        width: 75%;
    }
}
@media (max-width: 480px){  
    .city-state-zip-ctr{
        flex-direction: column;
        align-items: flex-start;
    }
    .city-input-ctr{
        width: 70%;
    }
    .state-province-select-ctr{
        width: 70%;
    }
    .zipcode-input-ctr{
        width: 70%;
    }
    .property-detail-section{
        width: 100%;
    }
    .property-detail-card-section label{
        font-size: 11px;
    }
}
