.dashboard {
    height: 100%;
    width: 100%;
    overflow: auto;
}
.page-title{
    margin-bottom: 6px;
    font-size: 14px;
    font-family: Open-Sans,sans-serif; 
    font-weight: bold;
}
.row {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    
    overflow: auto;
}
.margin-left-12{
    margin-left: 12px;
}
.card {
    width:20%;
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 13px;
    margin: 10px;
    padding: 5px 20px;
    align-self: normal;
    flex-direction: column;
    align-items: center;
    padding-bottom: 25px;
}
.card h1 {
    font-size: 1.8rem;
    margin: 10px 0;
}
.card  .user_icon{
    /* width: 49%; */
    height: 27%;
}
.card .system_icon{
    /* width: 49%; */
}
.card .group_icon {
    height: 27%;
}
.card p {
    /* font: normal normal normal 14px/20px SF Pro Display; */
     font-family: SF Pro Display, sans-serif;
     color: #1E2432;
     font-size: 13px;
}

.label-alighn-container{
    display: flex;
    align-items: flex-start;
}
.required-symbol{
    /* color: #890804; */
    color: #000;
    font-weight: bold;
}
.row .card .add_usr_btn {
    background-color: #82BA50;
}
.add_usr_btn, .add_group_btn, .add_system_btn {
    width: 90%;
}
.common-button, .cancel-button, .save-button, .role-save-button, .large-button, .signIn-button, .no-event-button, .small-button, .download_btn, .import-button{
    background-color: #224767;
    border: none;
    color: white;
    text-align: center;
    width: 180px;
    min-width: fit-content;
    padding: 12px;
    text-decoration: none;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
    font-family: Open-Sans,sans-serif;
    margin-right: 15px;
}
.import-button, .download_btn{
    padding : 7px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}
.import_icon{
    height: 27px;
    margin-left: 10px;
}
.button_parent_div{
    display: flex;
    flex-direction: row;
}
.common-button:hover, .save-button:hover, .small-button:hover, .large-button:hover, .no-event-button:hover, .role-save-button:hover, .download_btn:hover, .import-button:hover{
    background-color: #448CC9
}
.user_batch_import{
    width: 120px;
}
.small-button{
    margin-right: unset;
    height: fit-content;
    width: fit-content;
    padding: 5px;
}
@media screen and (max-width:600px) {
    .card{
        min-width:50%
    }
}
@media (max-width: 768px) {
    .dashboard {
        width: 100%;
    }
    .card {
        flex: 1 1 5rem;
        margin: 10px 10px;
    }

}
@media screen and (min-width: 769px) and (max-width: 1024px) {
    .dashboard {
        height: 83%;
        width: 100%;
    }
    .card {
        flex: 1 1 5rem;
        min-width: 30%;
    }
    .card .system_icon{
        width: 49%;
    }
}
@media (min-width: 1025px) and (max-width: 1440px) {
    .nav-container {
        width: 20%;
        height: 90%;
    }
    .dashboard {
        height: 83%;
        width: 100%;
    }
    .card{
        min-width: 24%;
    }
    .card .system_icon{
        width: 49%;
    }
    .card p{
        font-size: 12px;
    }
}
@media screen and (min-width: 1440px) {
   .columns {
        padding:15px 0px 0px 0px;
    } 
}
.service-box{
    display: flex;
}
/* users */

.user-page {
    display: flex;
    flex-direction: column;
    /* padding: 5px; */
    width: 100%;
    height: 100%;
    overflow: auto;
    font-family: Open-Sans,sans-serif;
}

.user-page .col-11 label{
    font-size: 13px;
    font-weight: bold;
}
.user-page .table-paginate-container{
    color: #7C7C7C;
} 

.user-page .table-data .image{
    height: 30px;
    border-radius: 50%;
}

.list-page-title{
    font-family: Open-Sans,sans-serif;
    font-size: 14px;
    font-weight: bold;
}
.group-page {
    display: flex;
    flex-direction: column;
    /* padding: 5px; */
    width: 100%;
    height: 100%;
    overflow: auto;
}
.columns {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 5rem;
    padding:10px 0px 0px 0px;
    align-items: flex-end;
    /* padding: 8px; */
}
.group-filters-ctr{
    align-items: center;
}

.service_columns {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
    /* flex: 0 0 5rem; */
    /* padding:18px 0px 0px 0px; */
    /* padding: 8px; */
}
.top-columns{
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 5rem;
    padding:13px 0px 0px 0px;
}
.col {
    /* margin:0px 5px 10px; */
    /* padding:10px; */
}
.col-100 {
    width: 100%;
}
.col-25{
    width:13%;
}
.col-33 {
    width: 16%;
}
.col-5{
    width:2%;
}
.col-1{
width: 1%;
}
.col-10{
    width: 10%;
}
.col-11 {
    width: 11%;
}

/* .col-12{
    width:12%
} */

.col-13{
    width:13%
}
.col-14{
    width: 13.5%;
    font-size: 12px;
}
.col-15{
    width: 15%;
    text-align: right;
}
.col-60 {
    width: 80%;
    font-size: 13px;
}

.add_user_btn {
    background-color: #289af5;
}
.batch_import_btn {
    margin-left: 12px;
    background-color: 	#003366;
}
.save-button{
    width: 130px;
    margin-right: unset;
}
.vertical_line{
    border-left: 1px solid #7c7a7a38;
    height: 40px;
    width: 10px;
}
.cancel-button{
    width: fit-content;
    background-color: unset;
    color: #000;
    margin-right: unset;
}
.cancel-button:hover{
    background-color: unset;
}
.btn_parent-center{
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
}
.service-btn {
    border: none;
    color: white;
    text-align: center;
    width:13%;
    padding: 14px 22px ;
    text-decoration: none;
    font-size: 13px;
    border-radius: 5px;
    cursor: pointer;
    white-space: nowrap;
}
.user-page .vertical-line{
    margin: 0px 10px;
}
.columns .col-60 p{
  font-size:13px;
  color: #555555;
 }
 .line-gap{
     line-height: 25px;
 }
select {
   
    padding: 4px 15px 4px 4px;
    margin-left: 2px;
    margin-right: 2px;
    min-height: 25px;
    border: 2px solid #bbbb;
    cursor: pointer;
    border-radius: 3px;
    color: black;
    font-size: 12px;
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    background: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='15' height='20' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='rgb(187, 187, 187)'/></g></svg>")
        no-repeat;
    background-position: right 5px top 50%;
    font-size: 13px;
    height: 25px;
    border-radius: 7px;
}
.search input[type="text"] {
    color: black;
    border: 2px solid #bbbb;
    font-size: 13px;
    height: 25px;
    border-radius: 7px;
    padding:3px 5px;
    width:100%;
    background: url('../../assets/svg/navIcons_search.svg') no-repeat right 3% center;
    background-size: 16px;
    padding-right: 30px;
}
.searchIcon_user svg {
    color: #fff;
    fill: currentColor;
    width: 44px;
    height: 44px;
    padding: 10px;
    color: #bbb;
}

.searchIcon {
    all: unset;
    cursor: pointer;
}
.blankImg{
    width:45px;
    border-radius: 50%;
}
.bold {
    font-size: 14px;
    font-weight: 600;
    margin-right: 10px;
}
.top-content {
    height: auto;
}
.bottom-content {
    height: auto;
}

.margin-left-40{
    margin-left: 40%;
}
.margin-left-1{
    margin-left: 2%;
}
.margin-left-5{
    margin-left: 33%;
    margin-top: -28%;
    white-space: nowrap;
}
.margin-left-5-percent{
    margin-left: -5%;
}
.margin-left-1-percent{
    margin-left: 1%;
}
.react-paginate ul {
    display: inline-block;
    padding-left: 0px;
    margin: 0px;
 }

.react-paginate li {
    display: inline-block;
    border: 1px solid #b3b3b3;
    color: #000;
    cursor: pointer;
    margin-right: 3px;
    border-radius: 5px;
    font-weight: 550;
}


.react-paginate li a {
      padding: 2px 5px;
      display: inline-block;
      color: #000;
      outline: none;
}
 
.react-paginate li.active {
    background: rgb(224, 224, 224);
    outline: none;
}
.disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
.react-paginate li.disabled a{
    color:#636363;
    outline: none;
}
.btn-span{
    display: flex;
}
.application-options-container{
    height: 20%;
}
.application-table-container{
    height: 80%;
}
.reclear{
    color: #9D9D9D;
    font-size: 15px;
    margin-left: 10px;
}
.user-reset-btn-ctr,.alarm-reset-btn-ctr,.view-grp-reset-btn-ctr  {
    padding-bottom: 4px;
}
.list-page-title-div{
    margin-bottom: 10px;
    margin-top: 10px;
}
.card-instruction{
    font-size:13px ;
    color: #707070;
    font-weight: bold;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-family: Open-Sans,sans-serif;
    margin: 15px 0px;
    align-items: center ;
}
.card-instruction svg{
    height: 17px;
    width:20px;
}
.card-instruction-bold
{
    color: #000;
    display: flex;
    align-items: center;
   
}
@media (max-width: 1024px){   
    .card-instruction{
        display: none;
    }
}
@media screen and (max-width: 768px){
   
    .col-11{
        width: 35%;
        margin-bottom: 10px;
    }
    .columns .col-33{
        width: 60%;
    }

}

@media screen and (max-width: 480px) {
    .group-reset-btn{
        width: 100%;
        margin: 10px;
    }
    .group-reset-btn .pointer{
        display: flex;
        justify-content: center;
        width: 100%;
    }
    .group-page .table-page-entry-container{
        margin-top: 10px;
    }
    .columns{
        justify-content: center;
        align-items: center;
    }
    .user-page .search{
        margin-bottom: 10px;
        margin-right: auto;
    }
    .user-page {
        /* padding-left: 30px; */
        width:100%;
    }
    .reclear{
        margin-right: auto;
    }
    .group-page {
        /* padding-left: 30px; */
        width:100%;
  }
    .user-page .user-filter-column{
        padding-top: 5px;
    }
    .columns .col p{
        /* text-align: center; */
    }
    .col-33 {
        padding-top: 2px;
        width: 100%;
        margin: 2px;
    }
    .group-page .col-33{
        padding-top: 2px;
        width: 100%;
        margin: 2px;
    }
    .col-12 {
        width: 100%;
        margin: 2px;
    }
    .group-page .col-12{
        width: 100%;
        margin: 2px;
    }
    .group-page .top-columns .col-14{
        width:100%;
        text-align: left;
    }
    .columns .col-10{
        width: 33%;
    }
    .col-5{
        width:10%;
    }

    .col-11{
        width:50%;
    }
    .col-14{
        width: 39%;
    }
    .col-60 {
        width: 100%;
    }
    /* .top-content{
        height: 55%;
    }
    .bottom-content{
        height:40%
    } */
    .user-page .table-page-entry-container{
        justify-content: center;
    }
    .user-page .col-11{
        font-size: 11px;
        font-weight: bold;
    }
   .top-columns .col-14{
        width: 40%;
        text-align: center;
    }
    .top-columns .col-12{
       width: 50%;
       text-align: center;
    }
    .batch_import_img{
        display: none;
    }
    .col-15{
        width:50%
    }
    .service_columns .col-20{
     width:100%;
    }
    .service-btn{
        width:50%;
    }
    .user-page select{
        margin-bottom: 5px;
    }
}

@media screen and(min-width:481px)and (max-width: 768px) {
    .col-33 {
        width: 100%;
        margin: 2px;
    }
    .top-content {
        height: 35%;
    }
    .user-page {
        padding-left: 38px;
    }
    .group-page {
        padding-left: 38px;
    }
    .columns {
        text-align: center;
        align-items: center;
        justify-content: center;
    }
    .col-12 {
        width: 100%;
        margin: 2px;
    }
    .responsive {
        width: 10%;
        text-align: left;
    }
    .col-60 {
        width: 100%;
        margin: 2px;
    }
    .search input[type="text"]{
        width:50%;
    }
    .margin-left-40{
        margin-left: 0%;
        margin-top: -22%;
    }
}
.batch_import_img{
    margin-right: 5px;
    vertical-align: middle;
    width:17px;
    margin-left: 30%;
}
/* .batch_import_btn{
    text-align: center;
    align-self: center;
} */



 @media screen and (min-width: 768px) and (max-width: 900px) {
    .col-33 {
        width: 30%;
    }
    .top-content {
        height: 35%;
    }
    .col-12 {
        
        margin: 2px;
    }
    .col-60 {
        width: 70%;
    }
    .top-content{
        height: 50%;
    }
    .bottom-content{
        height:50%
    }
    .columns .col-10{
        width: 20%;
    }
    .col-11{
        width:18%;
    }
    .col-14{
        width: 22%;
    }
} 

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .col-33 {
        width: 30%;
        margin: 2px;
    }
    .col-12 {
     
        margin: 2px;
    }
    .columns .col-10{
        width: 20%;
    }
    .col-11{
        width:18%;
    }
    .col-14{
        width: 22%;
    }
    .col-60 {
        width: 50%;
    }
    .top-content {
        height: auto;
    }
    .bottom-content{
        height:50%
    }
    .margin-left-5-percent{
        margin-left: 3%;
    }
    .col-15{
        width:20%;
     padding-top:5px
    }
}
@media (min-width: 1025px) and (max-width: 1440px) {
    .col-33 {
        width: 18%;
        margin: 2px;
    }
    .top-content {
        height: auto;
    }
    /* .col-12 {
        width: 14%;
    } */
    .batch_import_img{
        margin-left: inherit;
    }
}
