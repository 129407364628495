.integrations-page-container{
	width: 100%;
	height: 100%;
	overflow: auto;
}
.integrations-action-container{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
.integrations-search-input{
	color: black;
	border: 2px solid #bbbb;
	font-size: 13px;
	border-radius: 5px;
	padding:3px 5px;
	background: url('../../assets/svg/navIcons_search.svg') no-repeat right 3% center;
	background-size: 16px;
	margin-right: 5px;
}
.add-integrations-modal{
	width: 50%;
	height: auto;
    max-height: 95%;
	padding: 15px;
	display: flex;
    flex-direction: column;
}
.add-integration-model-content{
	max-height: 99%;
	overflow: auto;
}
.integrations-form-container{
	width: 100%;
}
.integrations-label-input-container{
	width: 100%;
	display: flex;
	padding: 10px;
	flex-wrap: wrap;
	padding-bottom: 0px;
}
.integrations-label-container{
	width: 35%;
	font-size: 14px;
}
.integrations-input-container{
	width: 65%;
}
.integrations-input{
	outline: none;
	border: none;
	color: black;
	border: 2px solid #bbbb;
	font-size: 13px;
	border-radius: 5px;
	padding: 3px 5px;
	width: 100%;
}
.integrations-input.invalid{
	border: 2px solid #890804 !important;
}
.integrations-input:active,
.integrations-input:focus{
	border: 2px solid #000;
}

.integrations-inner-form-container{
	width: 99%;
	margin-left: 1%;
}
.integrations-inner-form-title{
	font-size: 14px;
	font-weight: bold;
}
.integrations-image-view{
	height: 120px;
	width: 120px;
	display: flex;
	padding: 5px;
	margin: auto;
}

@media only screen and (max-width:768px) {
	.integrations-label-container{
		width: 100%;
	}
	.integrations-input-container{
		width: 100%;
	}
	.integrations-label-input-container{
		padding: 5px;
	}
	.add-integrations-modal{
		width: 90%;
	}
}
@media only screen and (min-width:1440px) {
	.add-integrations-modal {
		/* height: 60%; */
		width: 50%;
	}
}
@media only screen and (max-width:480px) {
	.integrations-search-input{
		margin: 0px;
	}
}
