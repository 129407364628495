.mustering-location-container{
    font-family: Open-Sans,sans-serif;
    overflow: auto;
}
.add-mustering-location-btn{
    border: none;
    font-family: inherit;
    padding: 10px 15px;
    border-radius: 5px;
    color: white;
    background-color: #448CCB;
    margin: 10px 0px;
    font-size: 14px;
    cursor: pointer;
}
.mustering-location-search-filter{
    width: 25%;
    display: flex;
    align-items: center;
    margin-top: 10px;
}
.mustering-location-search-filter input{
    font-family: inherit;
}
@media (max-width: 640px){   
    .mustering-location-search-filter{
        width: 40%;
    }
}
@media (max-width: 480px){   
    .mustering-location-search-filter{
        width: 80%;
    }
    .mustering-location-container .table-page-entry-container{
        margin-top: 10px;
    }
}
