.dashboard-card{
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin: 10px 20px 30px 10px;
    box-shadow: -8px 12px 18px 0 #dadee8;
    font-family: Poppins-regular, sans-serif;
    font-weight: 600;
    font-size: 18px !important;
    width: 22%;
}
.card-title-ctr{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}
.card-title-ctr svg{
    font-size: 40px;
    font-weight: 600;  
}
.large-button, .signIn-button, .no-event-button{
    width: -webkit-fill-available;
    margin-right: unset;
}
.card p{
    height: 47px;
}
.system_icon{
    height: 93px;
}
.facility-icon{
    color: #fd7e14 !important;
}
.accounts-icon{
    color: #6777ef !important;
}
.users-icon{
    color: #e83e8c !important;
}
.device-icon{
    color: #417c50 !important;
}
.status-count{
    font-size: 25px;
}

.no-menu-dashboard{
    width: 100%;
    height: 75%;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 1440px) {
    .dashboard-card {
        width: 14%;
    }
}

@media (max-width: 1025px) {
    .dashboard-card {
        width: 30%;
    }
}

@media (max-width: 960px) {
    .dashboard-card {
        width: 45%;
    }
}

@media (max-width: 640px) {
    .row {
        justify-content: center;
    }
    .dashboard-card {
        width: 85%;
    }
}

@media (max-width: 480px) {
    .dashboard-card {
        width: 85%;
    }
}
