.dropDownContainer{
    width: 100%;
    flex-direction: row;
    display: flex;
    border: 1px solid #333333;
    border-radius: 4px;
    background: #FFFFFF;
    justify-content: center;
    box-shadow: 0px 5px 8px #00000029;
    align-items: center;
}
.dropDownContainer:hover{
    cursor: pointer;
}
.drop-down-arrow-tones{
   width: 20%;
   display: flex;
   justify-content: center;
   align-items: center;
}
.drop-down-arrow-rotation{
    width:70%;
    transform: rotate(180deg);
}
.drop-down-arrow-tones img{
    height: unset;
    width: unset;
}
.selectedToneTitle{
    width: 70%;
    font-size: 14px;
    margin-left: 7px;
    margin-right: 7px;
    padding: 3px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
}
.dropdown-content-tones{
    max-height: 250px;
    overflow-y: auto;
    z-index: 1;
    position: absolute;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    color: #333;
    width: 100%;
    border-radius: 5px;
    font: normal normal medium 15px/28px SF Pro Display;
}
.tone-items{
    padding: 5px;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
    height: 100%;
}
.tone-items:hover{
    background: #E8E7E7;
}

.dropDownButton{
    display: flex;
    width: 100%;
}
.cont{
    width: 100%;
    position: relative;
    height: 100%;
}
@media (max-width:580px) {
    .dropDownContainer{
        height: 30px;
    }
}
