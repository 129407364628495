.no-current-event-container{
    height: 100%;
    width: 100%;
    overflow: auto;
}
.no-current-event-card{
    width: 25%;
    display: flex;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%);
    border-radius: 13px;
    margin: 10px;
    padding: 5px 10px;
    align-self: normal;
    flex-direction: column;
    padding-bottom: 25px;
}
.no-current-event_title{
    font: normal normal bold 24px/40px SF Pro Display;
    padding:10px;
    text-align: center;
}
.no-current-event-img{
  display: flex;
  justify-content: center;
}
.no-current-event-container .row .no-current-event-card  .no-current-event-content{
    font: normal normal normal 13px/18px SF Pro Display;
    margin:6%;
}
.no-event-button{
    margin: 6%;
}

@media(max-width:480px) {
    .no-current-event-card{
        width:100%;
    }    
}
@media(max-width:600px) {
    .no-current-event-row{
        margin-left:0%;
        justify-content: center;
    }
    .no-current-event-card{
        width:90%;
    }
   
    .no-current-event-content{
        text-align: center;
    }
}
@media(min-width:768px) {
    .no-current-event-card{
        width:35%;
    }
}
@media(min-width:1024px) {
    .no-current-event-card{
     width:25%
    }
}
@media(min-width:1440px){
    .no-current-event-card{
        width:20%
    }  
    
    .no-current-event_title{
        text-align: center;
    }
    .no-current-event-container .row .no-current-event-card  .no-current-event-content{
        font-size: 16px;
    }
}
