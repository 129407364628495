.event-time-picker {
  position: relative;
  height: 30%;
}

.event-time-picker-input {
  width: 87%;
  padding-left: 2%;
  border: 1px solid#B1B4AA;
  border-radius: 4px;
  height: 30px;
  margin-left: 0% !important;
  font-family: Open-Sans, sans-serif;
  /* font-weight: bold; */
  font-size: 14px;
  cursor: pointer;
  outline: none;
  padding: 4%;
}

.event-time-picker ::-webkit-scrollbar {
  display: none;
}

.time-picker-option {
  height: 30px;
}

.event-time-picker-input option {
  min-height: 50px !important;
  overflow-y: visible;
}

.options {
  position: absolute;
  z-index: 2;
  top: 100%;
  left: 0;
  width: 87%;
  max-height: 200px;
  overflow-y: scroll;
  background-color: white;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 5px 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.option {
  font-family: Open-Sans, sans-serif;
  font-size: 14px;
  color: #333333;
  margin: 3% 0% 3% 4%;
  font-weight: normal;
}

.options :hover {
  background-color: rgb(41, 138, 248);
}

.options :hover {
  color: rgb(255, 255, 255);
}

.custom-dropdown {
  position: relative;
  max-height: 30px;
}

.event-time-picker input {
  /* background-image: url("../../assets/images/Drop_down_arrow.png"); */
  background-image: url("../../assets/svg/Time_Picker_Icon.svg"),url("../../assets/images/Drop_down_arrow.png");
  background-repeat: no-repeat;
  background-position: right 17px top 61%, right 6px top 48%;
  color:black;
  border-radius: 5px;
  cursor: pointer;
  background-size: 18px 20px, 8px 15px;
}

.event-time-picker-input::placeholder {
  color: #707070;
  font-weight: bold;
}

.error {
  border: 1px solid #890804 !important;
}

@media (min-width: 481px)and (max-width :960px){   
  .event-time-picker input {
      background-image: url("../../assets/images/Drop_down_arrow.png");
      background-position: right 1px top 48%;
      background-size: 10px 15px, 5px 0px;
    }
}