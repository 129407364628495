.table-container{
    width: 100%;
    overflow: hidden;
}
.table-container.full-height {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
.table-options-container{
    width: 100%;
    height: 10%;
    display: flex;
    padding: 10px;
    font-size: 14px;
    justify-content: space-between;
}
.table-page-entry-container{
    display: flex;   
}
.table-entry-container{
    display: flex;
    margin-right: 10px;
    align-items: center;
    justify-content: center;
    color: #7C7C7C;
}
.table-paginate-container{
    display: flex;
    user-select: none;
    align-items: center;
    justify-content: center;
    color: #7C7C7C;
}
.table-paginate-inner-container{
    padding: 4px;
    margin-left: 3px;
    margin-right: 3px;
    border-radius: 3px;
    border: 1px solid #bbbb;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7C7C7C;
    height: 26px;
}
.table-paginate-next-prev{
    width: 12px;
    cursor: pointer;
}
.table-paginate-page{
    padding: 0px 8px;
    font-size: 12px;
    width: 41px;
    text-align: center;
}
.table-paginate-next{
    rotate: -90deg;
}
.table-paginate-prev{
    rotate: 90deg;
}
.table-title-container{
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold  ;
}
.table-inner-container{
    width: 100%;
    height: 90%;
    overflow: auto;
}
.table-header{
    display: flex;
}
.flow{
    overflow: visible;
}
.table-header-data{
    flex-grow : 1;
    padding: 10px;
    display: flex;
    font-size: 15px;
    user-select: none;
    box-sizing: border-box;
   border-bottom: 1px solid #CCCCCC;
   align-items: center;
   color: #666666;
}
.sort-icon{
    margin-left: 6px;
    height: 14px;
}
.pointer{
    cursor: pointer;
}
.table-header-data .active{
    filter: invert(20%) sepia(86%) saturate(2470%) hue-rotate(208deg) brightness(97%) contrast(104%); 
}
.table-body{
    display: flex;
    flex-direction: column;
}
.table-data-row{
    display: flex;
}
.table-data{
    flex-grow: 1 ;
    height: 64px;
    display: flex;
    padding: 15px 10px;
    align-items: center;
    box-sizing: border-box;
    border-bottom: 1px solid #CCCCCC;
    font-size: 14px;
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
    color: #555555;
}
.table-data.multiple-data {
    display: block;
}
.table-multiple-data{
    margin-right: 3px;
}
.table-btn-field{
    width: 70%;
    border: none;
    padding: 10px 20px;
    font-weight: bold;
    color: white;
    font-family: Open-Sans ,sans-serif;
    border-radius: 5px;
    display: flex;
    align-items: center;
    text-align: left;
    height: 45px;
}
.btn-txt {
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    line-clamp: 2;
    -webkit-line-clamp: 2;
    width: 100%;
}
.table-btn-field svg{
    margin-right: 10px;
    font-size: 15px;
}
.table-data a{
    color: #438AFE;
	text-decoration: none;
}
.table-data > span{
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.no-data{
    width: 100%;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.table-select{
    width: 75px;
    margin:  0px 10px;
    color: #7C7C7C;
    border: 1px solid #bbbb ;
    border-radius: 3px;
    background: url("../../assets/images/Drop_down_arrow.png")
    no-repeat;
    background-position: right 7px top 50%;
    background-size: 12px;
    height: 26px;
}
.icon-style{
    height: 25px;
    width: 25px;
    margin-right: 5%;
}
.alignItem{
    display: flex;
    align-items: center;
    justify-content: center;
}
.imageHover:hover{
    cursor: pointer;
}

.tone-lib-height{
    height: 323px;
    overflow: auto;
}


.tool-tip {
    position: relative;
  display: flex;
  justify-content: flex-end;
}
.tool-tip .tool-tip-text {
    display: none;    
    background-color: #35412C;
    color: #fff;
    border-radius: 6px;
    padding: 5px 5px;
    position: absolute;
    z-index: 9002;
    top: 105%;
    margin-top: 4px;
}
.tool-tip .tool-tip-text::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 99%;
    margin-left: -13px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #35412C transparent;
}
  .tool-tip-text {
    word-break: break-word;
}
  .tool-tip .tool-tip-text.top::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 99%;
  border-width: 5px;
  border-style: solid;
  border-color: #35412C transparent transparent transparent;
}
.tool-tip .tool-tip-text.top {
  margin-bottom: 4px;
}
.tool-tip:hover .tool-tip-text {
  display: block;
}
.bottom {
    justify-content: flex-end !important;
    margin: 10px 0;
}
@media only screen and (max-width: 600px) {
    .hide-mobile{
        display: none;
    }
    .table-options-container{
        height: 13%;
        flex-direction: column;
    }
    .table-inner-container{
        height: 87%;
    }
    .table-title-container{
        margin-bottom: 10px;
        justify-content: flex-start;
        font-size: 14px;
        font-weight: bold;
    }
    .table-page-entry-container{
        justify-content: flex-end;
    }
    .icon-style{
        margin-right:8%;
    }
}
@media only screen and (max-width: 480px){
    .table-page-entry-container {
        justify-content: center;
    }
    .icon-style{
        margin-right:8%;
    }
}
