.manage-device-modal-content{
    font-family: Poppins-regular, sans-serif;
    /* display: flex; */
}
.manage-device-modal-inner-content{
    display: flex;
}
.manage-device-details-ctr{
    width: 30%;
}
.manage-device-allocation-ctr{
    width: 70%;
}
.manage-device-details-title, .device-allocation-title{
    font-weight: bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
}
.highLight{
    color: #890804;
}
.highLight-input{
    border: 2px solid #890804;
}
.detach-ctr input{
    cursor: not-allowed;
}
.device-error-msg{
    margin-top: 10px;
    font-size: 14px;
    color: #890804;
    font-weight: bold;
}
.manage-device-details-title{
    color: #448CCB;
    font-size: 16px;
    margin: 0px 0px 10px 0px;
}
.manage-device-details-ctr label{
    font-size: 14px;
    font-weight: bold;
}
.device-allocation-sub-title{
    color: #707070;
    font-size: 14px;
    font-family: Open-Sans,sans-serif;
    margin-bottom: 10px;
}

.manage-device-details-ctr input[type="text"]{
    margin: 5px 0px 15px 0px;
    width: 90%;
    padding-left: 8px;
    padding-right: 8px;
}
.detach-ctr{
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-top: 10px;
    font-weight: bold;
}
.detach-ctr input{
    width: 17px;
    height: 17px;
    margin-right: 5px;
}
.detach-ctr span{
    margin-right: 10px;
}
.manage-device-detach-btn{
    color: #448CCB;
    padding-left: 10px;
    border-left: 1px solid #707070;
}
.manage-device-detach-btn:hover{
   cursor: pointer;
}
.detch-blocked{
    color: #448CCB;
    padding-left: 10px;
    border-left: 1px solid #707070;
    opacity: 0.5;
}
.detch-blocked:hover{
    cursor: not-allowed;
}
.manage-device-btn-ctr{
    display: flex;
    width: 100%;
    justify-content: center;
}
.alarm-card-container{
    display: flex;
    margin-bottom: 20px;
}
.alarm-card-container .icon{
    cursor: pointer;
    display: flex;
    justify-content: center;
}
.alarm-list-unselected-container-list, .alarm-list-selected-container-list{
    width: 45%;
}
.manage-device-modal{
    width: 80%;
}
@media (max-width: 1025px){
    .manage-device-modal{
        width: 85%;
    }
}
@media (max-width: 960px){
    .manage-device-modal{
        width: 90%;
    }
}
@media (max-width: 640px){
    .manage-device-modal-inner-content{
        flex-direction: column;
    }
    .manage-device-details-ctr, .manage-device-allocation-ctr{
        width: 95%;
        margin-bottom: 20px;
    }
    .alarm-card-container{
        flex-direction: column;
    }
    .manage-device-allocation-ctr .icon{
        flex-direction: row;
    }
    .manage-device-allocation-ctr .icon svg{
        transform: rotate(90deg);
        margin-bottom: 10px;
        cursor: pointer;
    }
}




@media (max-width: 480px){

}
