.mustering-details-container{
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.mustering-details-card{
    width: 300px;
    border-radius: 13px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,.2);
    display: flex;
    flex-direction: column;
    padding: 20px;
    margin: 20px;
}

.mustering-card-title{
    text-align: center;
    margin-bottom: 10px;
    text-align: center;
    max-height: 28px;
    margin-bottom: 10px;
    overflow: hidden;
}
.mustering-card-icon-details-container{
    display: flex;
    justify-content: center;
}
.mustering-card-icon-container{
    position: relative;
    width: 135px;
}
.mustering-triangle-icon{
    width: 100%;
    height: 100%;
    fill: rgb(245, 176, 86);
}
.mustering-alert-icon{
    left: 45px;
    position: absolute;
    top: 64px;
    width: 55px;
}
.alert-icon {
    width: 45px;
    height: 45px;
}
.mustering-card-description{
    margin-bottom: 10px;
    width: 100%;
    overflow:hidden;
    word-break: break-all;
    height: 75px;
    max-height: 75px;
}
.mustering-triggered-time{
    font-weight: bold;
    margin-bottom: 20px;
}
.view-mustering-button-ctr{
    background-color: #35412c;
    color: white;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
}
