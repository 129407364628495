.application-management-container{
    overflow: auto;
}

.application-management-container h4{
    margin: 25px 0pc;
}
.service-allocation-options-container{
    display: flex;
    width: 100%;
}
.service-allocation-option-container{
    display: flex;
    width: 15%;
}

.application-management-section{
    box-shadow: 0px 10px 20px #00000034;
    padding:20px 20px 20px 30px;
    border-radius: 3px;
    display: flex;
    flex-direction: column;
    font-family: SF Pro Display,sans-serif;
    letter-spacing: 0.5px;
    font-size: 13px;
    width: 1110px;
    color: #1E2432;
}
.application-management-section .row-1{
    display: flex;
    justify-content: right;
}
.application-management-section label{
    margin-right: 10px;
}

.application-management-section .row-1 input{
    width: 25%;
}
.application-management-section .row-2{
    display: flex;
    margin-bottom: 40px;
}
.application-management-section .row-2 div{
    margin-right: 20px;
    display: flex;
    align-items: center;
}
 select::selection {
    color: #606060;
  }
.application-management-section .row-2 div label{
    margin-right: 10px;
}
.selection-filter{
    width: 50%;
    display: flex;
    align-items: center;
}
.selection-filter-label{
    width: 20%;
    font-weight: bold;
    font-size: 14px;
    text-align: center;
}
.selection-filter-input{
    width: 50%;
}
.application-management-section .row-3{
    display: flex;
    flex-direction: column;
}
.application-management-section .row-3 .row-3-1 {
    display: flex;
    align-items: center;

}
.application-management-section .row-3 .row-3-1 h4{
    margin-right: 20px;
}
.application-management-section .row-3 .row-3-1 div{
    display: flex;
    align-items: center;
}
.application-management-section .row-3 .row-3-1 h4{
    margin-right: 25px;
}
.application-management-section .row-3 .row-3-2{
    display: flex;
    flex-wrap: wrap;
}
.application-management-section .row-3 .row-3-2 div{
    display: flex;
   justify-content: left;
    width: 18%;
    margin: 10px;
    align-items: center;
}
.application-management-section .row-3 .row-3-2 input{
    margin-right: 10px;
}
.application-management-btn-field{
    width: 1110px;
    /* display: flex;
    align-items: center;
    justify-content: center; */
    margin-top: 8px;
    
}
.application-management-container  input[type="checkbox"]{
    width: 18px;
    height: 18px;
    border-radius: 3px;
}
.application-management-btn-field{
    /* display: flex;
    justify-content: center; */
    text-align: center;
}
.user-group-filter{
    padding-top:15px;
    display: flex;
    justify-content: center;
}
.service-error{
    height: 10px;
    font-size: 14px;
    font-weight:900;
    text-align: center;
    color: #890804;
}
.page-title{
    display: flex;
    justify-content: space-between;
}
.service-allocation-error{
    display: flex;
    justify-content: center;
}
@media (max-width: 1440px){
    .application-management-section{
        width: 100%;
    }
    .application-management-btn-field{
        width: 100%;
    }
    .application-management-container{
        width: 100%;
        margin: 0 auto;
    }
}
@media (max-width: 1250px){
    .application-management-section .row-3 .row-3-2 div{
        width: 25%;
    }
}
@media (max-width: 1050px){
    .application-management-section .row-3 .row-3-2{
        overflow-y: auto;
    }
    .application-management-section .row-3 .row-3-2 div{
        width: 40%;
    }
}

@media (max-width: 1050px){
    .application-management-section .row-3 .row-3-1{
        margin: 0px;
    }
    .application-management-section{
        padding:30px;
    }
    .application-management-section .row-1 input{
        width: 50%;
        margin-bottom: 15px;
    }
    .application-management-section .row-2{
        margin-bottom: 15px;
    }
}
@media (max-width: 615px){
    .application-management-section .row-2 {
        flex-wrap: wrap;
    }
    .application-management-section .row-2 div{
        width: 30%;
        justify-content: right;
        margin: 5px;
    }
    .selection-filter{
        width: 90%;
    }
    .selection-filter-label {
        width: 30%;
    }
    .selection-filter-input {
        width: 70%;
    }
    .application-management-section .row-2{
        justify-content: center;
    }
    .application-management-section{
        box-shadow:none;
        width:100%
    }
    .application-management-section .row-1{
        justify-content: center;
    }
}
@media only screen and (min-width:481px) and (max-width:768px){
    .service-allocation-option-container{
        width: 20%;
    }
}
@media (max-width: 450px){
    .application-management-section .row-1 input{
        width: 60%;
    }
    .application-management-section .row-2 div{
        width: 40%;
    }
    .application-management-section .row-3 .row-3-2{  
        /* justify-content: center; */
    }
    .application-management-section .row-3 .row-3-2 div {
        /* width: 70%; */
        width:100%
    }
    .application-management-section .row-3 .row-3-2 div label{
        width: 42%;
    }
}
@media only screen and (min-width:320px) and (max-width:480px){
    .service-allocation-options-container{
        flex-direction: column;
        width:100%;
    }
    .service-allocation-option-container{
        width: 100%;
        margin:2%;
    }
    .service-allocation-option-container label{
        width: 40%;
    }
    .application-management-section{
        padding-top: 0px;
    }
}
@media (max-width: 370px){
    .application-management-section .row-2 div{
        width: 50%;
    }
    .application-management-btn-field  button{
        width: 50%;
    }
}
