/* Add User */
.add-user-container{
    overflow: auto;
}
.add-user-header{
    margin: 3px 0px 5px 2px;
}
.add-user-inner-container{
    display: flex;
    width: 100%;
}
.Add-user-content-container{
    box-shadow: 0px 10px 20px #00000034;
    padding: 15px;
    padding-right: 10px;
    border-radius: 10px; 
    color: black;
    font-size: 12px;
    font-family: Open-Sans,sans-serif;
    letter-spacing: 1px;
    display: flex;
    overflow: auto;
    flex-direction: column;
    width: 95%;
    margin: auto;
}
.Add-user-content-container .card-list-mobile, .Add-user-content-container .filter-list-mobile{
    display: none;
}
.add-sub-ctr-1{
    width: 35%;
    flex-direction: column;
}
.invalid-text-error{
    color:#890804
}
.add-sub-ctr-2{
    width:65%;
    flex-direction: column;
}
.Add-user-content-container div{
    margin-bottom: 10px;
}
.Add-user-content-container div div {
    display: flex;
}
.Add-user-content-container div div{
    margin-bottom: 0px;
}
.Add-user-content-container .filter-container{
    width: 100%;
}
.image-container{
    width: 30%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 5px;
}
.personal-details-input-container{
    width: 70%;
    display: flex;
    flex-direction: column;
}
.contact-input-container{
    width: 90%;
    display: flex;
    flex-direction: column;
}
.Add-user-content-container .image-container span{
    font-size: 9px;
    text-align: center;
    font-weight: bold;
}
.name-input-container{
    width: 90%;
}
.Add-user-content-container .name-input-container{
    display: flex;
}
.Add-user-content-container .row-2 label{
    cursor: pointer;
}
.user-list-option-container-list svg{
    font-weight: bold;
    font-size: 20px;
    cursor: pointer;
}
.Add-user-content-container .person-input-container span img{
    width:9px;
    margin-right: 5px;
}
.Add-user-content-container .person-input-container .name-input-container input{
    display: inline;
    width: 100%;
    margin: 5px 5px 5px 0px;
}
.Add-user-content-container .person-input-container .name-input-container {
    flex-direction: row;
}
.Add-user-content-container .person-input-container{
    display: flex;
    flex-direction: row;
}
.Add-user-content-container .contact-input-container span{
    margin-bottom: 5px;
}
.Add-user-content-container input[type="text"],
.Add-user-content-container input[type="email"],
.Add-user-content-container input[type="number"]{
    font-size: 12px;
    font-family: Open-Sans,sans-serif;
    letter-spacing: 1px;
    color:#77838F;
    border-radius: 0px;
    
}
.Add-user-content-container .name-input-container  input[type="text"]{
    color: black;
    font-weight: bold;
}
.Add-user-content-container .row-3 select,.Add-user-content-container .row-4 select{
    width: 50%;
    padding: 2px 7px;
    font-size: 12px;
    font-family: Open-Sans,sans-serif;
    color:#77838F;
    letter-spacing: 1px;

}
.Add-user-content-container .group-management-title{
    font-weight: bold;
    margin-bottom: 5px;
}
.Add-user-content-container .row-2 .role-container{
    display: flex;
    flex-wrap: wrap;
    border: none;
}
.Add-user-content-container div  h4{
    margin-bottom: 5px;
}
.Add-user-content-container .required-text{
    color:#890804;
    align-items: flex-end;
    font-size: 16px;
    font-family: SF Pro Display,sans-serif;
    width: 312px;
}
.Add-user-content-container .row-2 .role-container input{
    margin-right: 10px;
    border: 2px solid black;
}
.Add-user-content-container .row-2 .role-container input[type="radio"]{
    background: #dadada 0% 0% no-repeat padding-box;
    border: 3px solid #fdfdfd;
}
.Add-user-content-container .row-2 .role-container input[type="radio"]:checked{
    border: 3px solid #fdfdfd;
    background: #448CCB 0% 0% no-repeat padding-box;

}
.Add-user-content-container .row-2 .role-container div{
    display: flex;
    align-items: center;
}

.Add-user-content-container .row-2 .role-container div label{
    margin-right: 5px;
}
.Add-user-content-container .person-input-container .image-container{
    width: 30%;
    height: 85%;
    align-self: center;
}

.Add-user-content-container .row-4{
    margin-bottom: 10px;
}
.Add-user-content-container .row-5{
    margin-bottom: 10px;
}
.Add-user-content-container .row-4 input{
    width: 40%;
    margin: 0px;
}
.Add-user-content-container .row-5 input{
    width: 40%;
    margin: 0px;
}
.user-selection-id{
    font-size: 13px;
    color: #555555;
}
.user-image-view{
    height: 80%;
    width: 90%;
    border-radius: 50%;
    padding: 0px;
    cursor: pointer;
    border: 1px solid grey;
}

.Add-user-content-container .add-inner-image-ctr{
    width: 110px;
    height: 110px;
}
.Add-user-content-container .image-container .add-user-inner-x{
    font-size: 20px;
    width: 35px;
    height: 35px;
    bottom: 15px;
    right: 10px;
    font-weight: normal;
}
.image-file-upload-input{
    visibility: hidden;
    height: 0;
}
.user-page-select{
    width: 95%;

}
.profile-contact-image{
    width: 15px;
    height: 15px;
    margin: 0px 5px;
}
.contact-inner-input-container{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.contact-email-input{
    width: 100%;
    margin: 5px 5px 5px 0px;
    display: flex;
    flex-direction: row;
}
.contact-phone-input{
    width: 100%;
    margin: 5px 5px 5px 0px;
    display: flex;
    flex-direction: row;
}
.contact-location-input{
    width: 100%;
    margin: 5px 5px 5px 0px;
    display: flex;
    flex-direction: row;
}
.user-page-search-container{
    margin: 0;
}
.Add-user-content-container a{
    text-decoration: none;
    color: black;
    font-weight: bold;
}
.Add-user-content-container div div{
    display: flex;;
}
.Add-user-content-container .row-3 div{
    display: flex;
    align-items: center;
}
.Add-user-content-container .row-3 div a{
    color:#438AFE;
    margin-left: 10px;
    font-weight: normal;
    font-size: 13px;
    letter-spacing: 0px;
    overflow-y: auto;
}
.Add-user-content-container .row-6{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0px;
    margin-top: 10px;
}
.user-error-msg{
    color: #890804;
    font-weight: bold;
    margin-left: 5px;
}
.Add-user-content-container .link-container{
    margin-top: 15px;
}
.Add-user-content-container .row-3,.Add-user-content-container .row-4,.Add-user-content-container .row-5,.Add-user-content-container .row-2{
    flex-direction: column;
    margin-top: 10px;
}
.Add-user-content-container .user-list-row{
    cursor: pointer;
    margin: 1px;
    padding: 3px;
    width:95%;
    min-height: 23px;
}
.selected-mobile-group{
    background-color: #707070;
    color: white;
}
.group-management-container{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.group-management-title{
    font-weight: bold;
    margin-bottom: 10px;
}
.group-management-card-container{
    margin-top: 10px;
    align-items: center;
    width: 95%;
}
.group-management-card-container .ccard{
    display: flex;
    flex-direction: column;
    padding: 0px;
    width: 45%;
    height: 300px;
    max-height: 250px;
    border-radius: 7px;
}
.group-management-card-label{
    display: flex;
    justify-content: space-between;
    background-color: #448CCB;
    color: white;
    padding: 10px
}
.group-management-card-label input[type="checkbox"]{
    border:1px solid white;
    background-color: #448CCB;
    margin-left: 2px;
    width: 15px;
    height: 15px;
    color: white;
    font-size: 10px;
    font-weight: normal;
}
.group-management-card-label  input[type="checkbox"]:checked::after{
    content: '\2713';
    background-color: white;
    color: #448CCB;
    width: 15px;
    height: 15px;
    display: flex;
    justify-content: center;
    align-items: center;  
}
.in-group-card-label input[type="checkbox"]{
    background-color: black;
}
.in-group-card-label input[type="checkbox"]:checked::after{
    color: black;
}
.grp-mng-checkbox-cntr{
    display: flex;
    align-items: center;
}
.in-group-card-label{
    background-color: black;
}
.user-list-name{
    width: 100%;
}
.group-management-card-container .user-list-option-container-list{
    margin: 0px 10px;
}
.card-data{
    display:flex;
    flex-direction: column;
}
.card-search-container{
    padding: 5px;
    border-bottom: 1px solid #E8E7E7;
}
.card-search-container .card-search{
    margin-left: 5px;
    padding: 5px;
    border: 1px solid #E8E7E7;
    width: 90%;
}
.card-data{
    max-height: 95%;
    overflow: auto;
    padding: 10px;
}

.Add-user-content-container .row-2 .role-container input {
    margin-right: 10px;
    border: 2px solid black;
}
.invalid-select-error{
    border:2px solid #890804;
}
.invalid-radio-error{
    color: #890804;
    font-weight: bold;
}
.add-card-data-ctr{
    max-height: 99%;
}
.Add-user-content-container .card-instruction{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.Add-user-content-container .card-instruction div{
    display: flex;
    flex-wrap: nowrap;
}
.Add-user-content-container .view-edit-role-ctr{
    /* font-size: 15px; */
    cursor: pointer;
    margin-top: 15px;
}
.Add-user-content-container .view-edit-label{
    color: #448CCB;
    font-weight: bold;
}
.Add-user-content-container  .users-role-container{
    height: 125px;
}
.Add-user-content-container  .users-role-container label{
    max-height: 70px;
    overflow: hidden;
    word-break: break-word;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    -webkit-box-orient: vertical;
    white-space: pre-line;
}
.invalid-label{
    color: #890804;
}
.empty-div{
    height: 38px;
}
.deviceBased{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: fit-content;
}
.usename-div{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}
.usernameResetPass{
    display: flex;
    flex-direction: column;
}
.resetPass{
    display: flex;
    flex-direction: column;
}
.credentialText{
    top: 602px;
    left: 461px;
    width: 150px;
    height: 18px;
    font-family:  SF Pro Display,sans-serif;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1px;
}
.usernameNdPassword{
    background: #F5F5F5 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    width: 275px;
    height: 35px;
    padding: 8px;
    padding-block: 15px;
    margin-top: 10px;
    border: none;
}
.password{
    font-size: x-large;
}
.usernameDivNdPasswordDiv{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.passwordDivNdSubmit{
    margin-top: 10px;
    width: 100%;
}
.resetPassText{
    font-weight: normal !important;
    width: fit-content;
    margin-left: 9px;
    display: flex;
    align-items: center;
}
.resetPass{
    margin-left: 40px;
    margin-top: 6px;
}
.usernameNdReset{
    display: flex;
    flex-direction: row;
    align-items: center;
}
.userSubmitButton{
    float: right;
    width: 20%;
    margin-right: 40px;
}
.passWordField{
    width: 35%;
}.emptydiv{
    width: 43%;
}
.divAlign{
    display: flex;
    flex-direction: row;
}
.buttonDiv{
    margin-right: 40px;
    align-self: flex-end;
}
.alignButton{
    justify-content: flex-end;
    display: flex;
    height: fit-content;
}
.resetPassCheck{
    width: 20px !important;
    height: 20px !important;
}.errorMsg{
    display: flex;
    flex-direction: row;
}
.invalid-error-textBox{
    border: 2px solid #890804;;
}
@media screen and (min-width: 1440px) {
    .Add-user-content-container {
        width: 90%;
        margin: auto;
    }
    .Add-user-content-container .person-input-container .image-container {
        height: 100%;
    }
    .user-image-view{
        height: 75%;
        width: 80%;
    }
}
@media (max-width: 1024px){   
    .Add-user-content-container .card-instruction{
        display: none;
    }
    .Add-user-content-container .row-6{
        justify-content: flex-end;
    }
}
@media (max-width: 1025px){ 
    .add-sub-ctr-1 {
        width: 70%;
    }
    .add-sub-ctr-2 {
        width: 100%;
    }

    .group-management-card-container{
        width: 90%;
        margin: auto;
    }
    .add-user-inner-container{
        flex-direction: column;   
    }
}

@media (max-width: 640px){   
    .add-sub-ctr-1{
        width: 100%;
    }
    .group-management-card-container{
        flex-direction: column;
    }
    .user-list-option-container-list svg{
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg);
        margin-bottom: 10px;
    }
    .group-management-card-container .ccard{
        width: 100%;
    }
    .group-management-card-container .user-list-option-container-list{
        flex-direction: row;
        margin-top: 20px;
    }
    .Add-user-content-container .row-6{
        flex-direction: column;
    }
    .deviceBased{
        flex-direction: column;
    }
    .empty-div{
        height: 10px;
    }
    .resetPass{
        margin-left: unset;
    }
    .divAlign{
        flex-direction: column;
    }.buttonDiv{
        flex-direction: row;
        align-self: center;
        margin-right:unset;
        margin-top: 15px;
    }.errorMsg{
        flex-direction: column;
    }.user-error-msg{
        margin-top: 10px;
    }
}

@media (max-width: 580px){  
    .Add-user-content-container {
        width: 100%;
        box-shadow: none;
        padding:10px;    
        
    }
    .name-input-container{
        width: 100%;
    }
    .personal-details-input-container{
        width: 100%;
    }
    .contact-input-container{
        width: 100%;
    }
    .Add-user-content-container .person-input-container{
        flex-direction: column;
    }
    .Add-user-content-container .person-input-container .image-container{
        width: 45%;
        align-items: center;
    }
    .Add-user-content-container input[type="image"]{
        width: 90px;
        height: 90px;
        text-align: center;
    }
    .Add-user-content-container .row-2 .role-container{
        align-items: center;
        width: 90%;
        margin: auto;
    }
    .Add-user-content-container .row-2 .role-container div{
        justify-content: right;
    }
    

}

@media (max-width: 340px){   
    .Add-user-content-container .row-2 .role-container{
        width: 50%;
    }
    .Add-user-content-container .person-input-container .image-container{
        width: 40%;
       
    }
}
@media (max-width: 1024px){   
    .Add-user-content-container  .card-data-list, .Add-user-content-container  .filter-card-data-list{
     display: none;
    }
    .Add-user-content-container  .card-list-mobile, .Add-user-content-container  .filter-list-mobile {
      display: block;
    }
  }
