
.deployment-configuration-ctr{
    overflow: auto;
}
.deployment-setup-ctr{
    font-family: Open-Sans,sans-serif;
}
.deployement-setup-configuration-ctr{
    padding-left: 10px;
}
.disableUsernameEdit{
    -webkit-text-fill-color: black;
}
.disableUsernameEdit:hover{
    cursor: not-allowed;
}
.disablingEditConfig:hover{
    cursor: not-allowed;

}
.highlighting{
    color: #890804;
}
.error-ctr{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
    color: #890804;
    font-size: 12px;
    font-weight: bold;
    height: 15px;
}
.diablingCtr{
    opacity: 0.5;
}
.diablingCtr:hover{
    cursor: text !important;
}
.border-color-error{
    border: 2px solid #890804  !important;
}
.input-error-border input[type="number"]{
    border: 2px solid #890804  !important;
}
.deployement-setup-configuration-title{
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0px;
    letter-spacing: 0.5px;
}
.manage-config-btn-ctr{
    display: flex;
    width: 100%;
    justify-content: center;
    height: 41px;
}
.manage-config-cancel-btn{
    background-color: white;
    color: black;
    margin-right: 25px;
    width: auto;
    font-size: 13px;
    font-family: Open-Sans,sans-serif;
    border-radius: 6px;
    padding: 10px;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    justify-content: center;
    display: flex;
    align-items: center;
}

.goToDeviceLink{
    color: #448CCB;
}
.goToDeviceLink:hover{
    cursor: pointer;
}
.deployment-checkbox-ctr{
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: bold;
}
.deployment-checkbox-ctr span{
    margin: 5px 5px 5px 0px;
    letter-spacing: 0.5px;
}
.deployment-checkbox-ctr input[type="checkbox"]{
    width: 19px;
    height: 19px;
    border: 2px solid #448CCB;
}
.deployment-checkbox-ctr input[type="checkbox"]:checked::after{
    content: '\2713';
    font-weight: bold;
    background-color: #448CCB;
    color: white;
    width: inherit;
    height: inherit;
    padding-left: 2px
}
.deployment-config-description{
    font-size: 12px;
    color: #333333;
    margin: 10px 0px;
}
.deployment-input-ctr{
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
}
.deployment-input-ctr input{
    background-color: white;
    padding-left: 8px;
    padding-right: 8px;
}
.device-based-alerting-ctr{
    margin-top: 19px;
}
.mobile-inactivity-ctr{
    margin-top: 13px;
}
.mobile-inactivity-charging-ctr{
    margin-top: 13px;
}
.deployment-input-ctr label, .deployment-input-ctr span{
    font-size: 14px;
    letter-spacing: 0.5px;
    font-weight: bold;
}
.device-based-alerting-input-ctr{
    display: flex;
}
.mobile-inactivity-ctr input{
    width: 70px;
}
.mobile-inactivity-ctr .tool-tip-text{
    font-size: 12px;
    color: #333333;
    font-weight: normal;
}
div.absolute {
    bottom: 10px;
    margin-top: 15px;
  } 
.inactive-input-ctr span{
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: bold;
}
 input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
       -webkit-appearance: none;
        margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}
