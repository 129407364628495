.facility-page-container{
	width: 100%;
	height: 100%;
	overflow: auto;
}
.facility-action-container{
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}

.facility-search-input{
	color: black;
	border: 2px solid #bbbb;
	font-size: 13px;
	border-radius: 5px;
	padding:3px 5px;
	background: url('../../assets/svg/navIcons_search.svg') no-repeat right 3% center;
	background-size: 16px;
	margin-right: 5px;
}
.add-facility-modal{
	width: 50%;
	padding: 10px;
	height: auto;
    display: flex;
    flex-direction: column;
    max-height: 95%;
    padding: 15px;
}
.facility-form-container{
	width: 100%;
}
.facility-label-input-container{
	width: 100%;
	display: flex;
	padding: 10px;
	flex-wrap: wrap;
	padding-bottom: 0px;
}
.facility-label-container{
	width: 35%;
	font-size: 14px;
}
.facility-input-container{
	width: 65%;
}
.facility-input{
	outline: none;
	border: none;
	color: black;
	border: 2px solid #bbbb;
	font-size: 13px;
	border-radius: 5px;
	padding: 3px 5px;
	width: 100%;
}
.facility-input.invalid{
	border: 2px solid #890804 !important;
}
.facility-input:active,
.facility-input:focus{
	border: 2px solid #000;
}

.facility-inner-form-container{
	width: 99%;
	margin-left: 1%;
}
.facility-inner-form-title{
	font-size: 14px;
	font-weight: bold;
}
.facility-image-view{
	height: 120px;
	width: 120px;
	display: flex;
	padding: 5px;
	margin: auto;
}
@media only screen and (max-width:768px) {
	.facility-label-container{
		width: 100%;
	}
	.facility-input-container{
		width: 100%;
	}
	.facility-label-input-container{
		padding: 5px;
	}
	.add-facility-modal{
		width: 90%;
	}
}
@media only screen and (min-width:1440px) {
	.add-facility-modal {
		width: 50%;
	}
}
