.document-container {
  width: 100%;
  font-family: Open-Sans, sans-serif;
  font-size: 13px;
  margin-top: 11px;
}
.add-file-button{
  width: fit-content !important;
}
.category-name button,
.file-edit-wrapper button,
.paginate-button button,
.files-edit-popup button,
.menu-category button,
.add-file-button{
  all: unset;
}

.files-edit-popup {
  background-color: #77787B;
  width: 54px;
  height: auto;
  border: 1px solid #ccc;
  padding-top: 3px;
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 4px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
  top: 0; 
  left: 35px;
  z-index: 10;
  transition: opacity 0.3s ease-in-out;
  position: absolute;
}

.files-edit-popup-option {
  color: #FFFFFF;
  font-size: 12px;
  font-family: SF Pro Display;
  padding: 2px;
}

.header {
  margin-bottom: 10px;
  width: 100%;
  font-family: Open-Sans, sans-serif;
  color: #666666;
  font-size: 15px;
}
.flex,.row-documents{
  justify-content: center;
  align-items: center;
}
.documents-search {
  margin-top: unset !important;
  width: unset !important;
}
.row-documents {
  margin: 5px 0;
  height: 27px;
}
.hover-reorder:hover{
  visibility: visible;
  cursor: url("../../../../assets/svg/reorder-icon.svg"),auto;
}
.hover-reorder{
  height: 20px;
  width: 20px;
}
.cell {
  padding: 10px;
  align-items: center;
  color: #555;
}
.files-edit-popup button:hover{
  background-color: #a0a0a0
}
.created-by-container{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 15%;
}

.toggle-button {
  margin-right: 10px;
  width: 15px;
  height: 8px;
}
.rotate{
  transform: rotate(180deg);
}
.children {
  flex-direction: column;
  margin-top: 10px;
}

.add-file {
  margin-bottom: 10px;
  margin-left: 20px;
  font-family:  Poppins-regular;
  color: #333333;
}
  
.width-increase {
  margin-left: 20px;
}

.menu-category {
  width: 50%;
}
.reorder {
  width: 8%;
  justify-content: center;
}
.last-updated {
  width: 25%;
  justify-content: center;
}
.created-by{
  width: 15%;
  justify-content: center;
}
.created-by-container >.created-by {
  width: 100%;
  text-align: center;
  -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    word-break: break-word;
}
.manage-category {
  width: 10%;
}
.icons-dimention{
  width: 15px;
  height: 13px;
  margin-right: 10px;
}
.height-adjust{
  height: 18px !important;
}

.add-file img{
  width: 13px;
  height: 13px;
}
.add-file img:nth-of-type(2) {
  margin-right: 10px; 
  height: 18px !important;
  margin-left: 5px;
}

.file-edit{
  width: 16px;
  height: 17px;
  margin-left: 10px;
  margin-right: 6px;
}

.file-edit-wrapper button{
  width: 100%;
}
.reorder img{
  width: 11px;
  height: 11px;
}
.group-restriction{
  width: 32px;
  height: 16px;
}
.no-category-files{
  margin-left: 26px;
}
.add-sub-category{
  width: 13px;
  height: 13px;
  margin-left: 10px;
  margin-right: 5px;
}
.file-edit-wrapper{
  align-items: center;
  position: relative
}
.category-name{
  font-weight: bold;
  font-size: 14px !important;
  color: #555555;
}
.files-name{
  color: #448CCB;
}
.files-name:hover,
.add-sub-category:hover,
.add-file:hover,
.file-edit:hover,
.files-edit-popup-option:hover,
.toggle-button:hover
{
  cursor: pointer;
}

.buttons-wrapper,.button-and-pagination-wrapper,.file-edit-wrapper,.add-file,.children,.header ,.flex,.row-documents,.cell{
  display: flex;
}
.button-and-pagination-wrapper{
  justify-content: space-between;
}
.botton-pagination{
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
  margin-top: 20px;
}
.doc-table-font{
  font-size: 14px;
}
.category-name-wrapper{
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;
  word-break: break-word;
}

.restrict-pointer{
  pointer-events: none;
}

.category-table {
  margin-top: 2%;
  margin-bottom: 3%;
}

.reorder-btn{
  display: flex;
  margin-left: 2%;
}
.file-preview-model {
  z-index: 1;
}
@media (min-width: 300px) and (max-width: 800px) {
  .button-and-pagination-wrapper{
    flex-direction: column !important;
  }
  .doc-table-font{
    margin-top: 10px;
    justify-content: end;
  }
  .reorder-btn{
    margin-top: 2%;
    margin-left: 0;
  }

}

@media(max-width: 600px) {
  .last-updated,.created-by,.reorder{
    display: none !important;
  }
  .menu-category{
    width: 74%;
  }
  .reorder{
    width: 10%;
  }
  .manage-category{
    width: 15%;
  }
}
