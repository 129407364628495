
.create-alert-container{
    font-family: Open-Sans,sans-serif;
    font-size: 13px;
    height: 100%;
    padding: 5px 15px;
    overflow: auto;
    display: flex;
    flex-direction: column;
}
.align{
    width: 11px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.event-name-input-box input{
    width: 50%;
    font-size: 13px;
    font-weight: bold;
    border-radius: 0px;
    font-family: Open-Sans,sans-serif;
}
.event-title-input-box{
    font-weight: bold;
    margin-bottom: 5px;
}
.create-alert-selection-container{
    width: 100%;
    flex:1;
    -webkit-flex:1;
    display: flex;
    justify-content: space-between;
    height: 60%;
    padding-bottom: 5px;
    margin-top: 25px;
}
.event-name-input-box{
    margin-bottom: 5px;
}
.create-alert-icon-color-selection-container{
    display: flex;
    align-items:flex-end;
    margin: 7px 0px;
}
.create-alert-select-btn-container{
    display: flex;
    flex-direction: column;
    width: 30%;
}
.alarm-icon {
    width: 45% !important;
}
.create-alert-select-btn-container label{
    font-weight: bold;
    margin-bottom: 7px;
}
.create-alert-select-btn-container .invalid{
color:#890804;
}
.create-alert-user-unselected-list{
    width: 45%;
    height: 100%;
}
.create-alert-user-unselected-list .ccard{
    height: 90%;
}

.create-alert-user-selected-list{
    width: 45%;
}
.create-alert-user-selected-list .ccard{
    height: 90%;
}
.create-alert-container .label-checkbox-container{
    display: flex;
    margin: 10px 0px;
}
.create-alert-container .label-checkbox-container div{
    display: flex;
    margin-left: 10px
}
.create-alert-container .label-checkbox-container .label-checkbox-title{
    font-weight: bold;
}
.create-alert-container .label-checkbox-container div input[type="checkbox"]{
    margin-left: 5px;
    width: 15px;
    height: 15px;
    font-size: 10px;
}
.add-new-icon-btn{
    height: 25px;
    display: flex;
    align-items: center;
    margin-left: -50px;
    margin-bottom: 7px;
    width: 27%;
}
.add_icon_symbol{
    margin: 3%;
}
.add_icon_btn{
    background-color: transparent;
    border: none;
    color: #438afe;
}
.add-new-icon-btn,.add-new-icon-btn button{
    cursor: pointer;
}
.create-alert-btn-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
.dropdown {
    width:80%;
    position: relative;
    user-select: none;
    cursor: pointer;
}

.dropdown .dropdown-btn{
    padding:10px;
    background-color: #ffff;
    border:1px solid #EDF1F7;
    font-weight: bold;
    color: #333;
    display: flex;
    justify-content: space-between;
    color: #222B45;
    font: normal normal bold 12px/20px Poppins;
}
.dropdown .dropdown-content{
    z-index: 99;
    position: absolute;
    padding:10px;
    background: #fff;
    box-shadow: 3px 3px 10px 6px rgba(0,0,0,0.06);
    color:#333;
    width: 100%;
    border-radius: 5px;
    font: normal normal medium 15px/28px SF Pro Display;
}
.dropdown .dropdown-content .dropdown-item{
    padding:10px;
    cursor: pointer;
    transition: all 0.2s;
    display: flex;
}
.dropdown .dropdown-content .dropdown-item:hover{
    background: #E8E7E7;
}
.dropdown-content .dropdown-item .select-box-icon svg{
     height: 100%;
}
.selected-value{
    padding-left:12px;
    word-break: break-all;
}
.drop-down-arrow{
    width:70%;
}
.drop-down-arrow-rotation{
    width:70%;
    transform: rotate(180deg);
}
.select-box-value{
    margin-left:12%;
    text-align:left;
    word-break: break-all
}
.create-alert-required-text{
    color: #890804;
    font-weight: bold;
}

.enable-description-checkbox-ctr input[type="checkbox"]{
    margin-right: 5px;
    border: 2px solid black;
    width: 17px;
    height: 17px;
}

.enable-description-checkbox-ctr {
    font-family: SF Pro Display, sans-serif;
    font-size: 16px;
    letter-spacing: 1px;
    margin-top: 10px;

    display: flex;
    align-items: center;
    flex-wrap: wrap;

    margin-right: 20px;
    font-weight: bold;
    letter-spacing: 1px;

}
.enable-description-label{
    cursor: pointer;
    font-size: 13px;
    letter-spacing: 1px;
}

.select-card{
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    margin-top: 10px;
}

.select-card button{
    width: 120px;
    letter-spacing: 1.13px;
    -webkit-text-fill-color: #1E2022;
    font-size:16px;
    font-family: Open-Sans,sans-serif;
    background: #ffffff;
    border: 0px;
}
.select-card button:hover{
    cursor: pointer;
}
.card-selected{
    font-weight: bold;
}
.vertical-line{
    width: 0px;
    border-left: 1px solid #7C7A7A38;
    height: 22px;
    margin: 0px;
}


@media (max-width: 700px){
    .select-card button{
        width: 108px;
    }
}
@media (min-width: 700px) and (max-width:912px){
    .add-new-icon-btn{
        margin-left: 0px;
    }
}

@media (max-width:600px) {
    .create-alert-modal{
        width: 90%;
    }
    .create-alert-modal{
        width: 90%;
    }
    .select-card button{
        width: 108px
    }
}
@media (max-width:400px) {
    .create-alert-modal{
        width: 90%;
    }
    .create-alert-modal{
        width: 90%;
    }
    .select-card button{
        width: 108px
    }
}
@media (max-width: 480px){
    .create-alert-container{
        display: block;
    }
    .create-alert-selection-container{
        flex-direction: column;
        height:auto;
    }
    .create-alert-user-unselected-list,.create-alert-user-selected-list{
        width: 95%;
    }
    .create-alert-selection-container .user-list-option-container-list{
        flex-direction: row;
        width: auto;
        margin-top: 15px;
    }

    .create-alert-selection-container .ccard{
        /* min-height: 150px;
        max-height: 250px; */
    }
    .ccard{
        max-height: 50px;
    }
    .create-alert-icon-color-selection-container{
        flex-direction: column;
        align-items: flex-start;
    }
    .add-new-icon-btn {
        margin: 0px;
        margin-top: 10px;
        width: 41%;
    }
    .event-name-input-box input{
        width: 80%;
    }
    .create-alert-select-btn-container{
        width:100% !important
    }
    .select-card{
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
    }
    .select-card button{
        font-size: small;
    }
    .card-container {
        display: flex;
        justify-content: center;
        align-items: center;}
        .vertical-line{
            width: 0px;
        }
}
@media (max-width: 370px){
    .select-card button{
        width: 30%;
        font-size: small;
    }
}
