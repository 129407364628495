.page-not-found-container {
	width: 100%;
	height: 100%;
	padding: 15px;
}

.page-not-found-inner-container {
	display: flex;
	box-shadow: 0px 0px 15px 5px #00000034;
	height: 100%;
}

.page-not-found-text {
	margin: auto;
	padding-left: 4%;
	width: 37%;
}

.page-not-found-img {
	width: 60%;
	display: flex;
	align-items: center;
	justify-content: center;
}

.page-not-found-title {
	font-size: 40px;
	font-family: Arial, Helvetica, sans-serif;
	font-weight: bolder;
	color: #000060
}

.page-not-found-content {
	margin-top: 6%;
	font-size: 14px;
	font-family: Poppins, sans-serif;
	font-weight: 600;
	color: #000060;
}

.page-not-found-btn {
	margin-top: 8%;
}

.page-not-found-btn button {
	background-color: #FE676E;
	padding: 13px;
	width: 30%;
	border: none;
	color: #ffffff;
	border-radius: 25px;
	font-size: 13px;
	font-weight: 600;
	cursor: pointer;
	font-family: Poppins, sans-serif;
}

.page-not-found-img img {
	width: 80%;
}

@media(max-width:481px) {
	.page-not-found-btn button {
		width: 40%;
	}
	.page-not-found-btn button {
		padding: 10px;
		width: 30%;
	}
	.page-not-found-title {
		font-size: 50px;
	}

	.page-not-found-content {
		font-size: 12px;
	}
}

@media(max-width:320px) {
	.page-not-found-btn button {
		width: 40%;
		padding:7px
	}

	.page-not-found-title {
		font-size: 50px;
	}

	.page-not-found-content {
		font-size: 12px;
	}
}

@media(max-width:960px) {
	.page-not-found-inner-container {
		flex-direction: column;
	}

	.page-not-found-text {
		margin-top: 5%;
		text-align: center;
		width: 100%;
	}

	.page-not-found-img {
		width: 100%;
		margin-top: 3%;
		text-align: center;
		align-items: center;
	}

	.page-not-found-img img {
		width: 70%;
		margin-top: 2%;
	}

	.page-not-found-container {
		overflow: auto;
	}

	.page-not-found-btn button {
		width: 30%
	}
}

@media (min-width:961px) and (max-width:1024px) {
	.page-not-found-btn button {
		width: 40%
	}
}