.application-options-container{
    height: auto;
}
.application-table-container{
    height: 80%;
    margin-top: 20px;
}
@media(max-width:600px) {
    .application-options-container{
        padding:5px;
    }
    .application-table-container{
        margin-top:0px;
    }
}