.className-title{
    margin-right: 60px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: -moz-fit-content;
    max-width: 50%;
}
.back-to-classes button {
    background-color: white;
    white-space: nowrap;
    cursor: pointer;
    color: black;
    border: none;
    font-family: SF Pro Display, sans-serif;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    align-items: center;
}
.button-arrange{
    justify-content: space-between;
}
.classes-search-wrap{
    width: 78%;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.classes-search-wrap > .col-33{
    width: 28% !important;
}

.classes-filter-ctr{
    padding: unset !important;
}

.classes-filter-ctr > .col-12 {
    margin: unset;
    margin-right: 10px;
}

.classes-filter-ctr > .col-12 > select {
    margin-left: unset;
}
@media (min-width: 420px) and (max-width: 767px) {
    .classes-search-wrap{
        width: 50%;
    }
    .classes-search-wrap > .col-33{
        width: 100% !important;
        margin-top: 0px !important;
    }
    .classes-search-wrap > .group-reset-btn{
        width: unset !important;
    }
    .classes-students-list-title{
        justify-content: flex-start;
    }
    .className-title{
        max-width: 100%;
    }
}

@media (min-width: 220px) and (max-width: 420px) {
    .classes-filter-ctr {
        justify-content: flex-start !important;
    }
    .classes-search-wrap{
        width: 100%;
    }
    .classes-search-wrap > .col-33{
        width: 80% !important;
    }
    .classes-search-wrap > .group-reset-btn{
        width: unset !important;
    }
    .classes-filter-ctr > .groups-select-filter-container{
        width: 100% !important;
    }
    .classes-students-list-title{
        justify-content: flex-start;
    }
    .back-to-classes{
        width: 100%;
        justify-content: flex-end;
        display: flex;
        margin-top: 5px;
    }
    .className-title{
        max-width: 100%;
    }
}
