/* NavBar */

.side-navbar {
    height: 100%;
    padding: 8px;
    background-color: black;
    position: absolute;
    top: 0px;
    z-index: 99;
}
.side-navbar .logo-details {
    display: flex;
}
.side-navbar .logo-details {
    display: flex;
}
.side-navbar .logo-details img {
    padding: 2%;
    max-width: 95%;
}

.side-navbar .login-details {
    color: #fff;
    padding: 10px;
    font-size: 13px;
    font-weight: 800;
    font-family: Open-Sans, sans-serif;
    line-height: 25px;
}
.side-navbar .search-box {
    display: flex;
    padding: 10px 5px ;
}
.empty-icon-ctr{
    display: flex;
    width: 25px;
    height: 25px;
    margin-left: 5px;
}
.side-navbar .search-box input[type="text"] {
    padding: 10px 18px;
    font-size: 15px;
    border: none;
    border-radius: 5px;
    color: #fff;
    width: 100%;
    background: url('../../assets/svg/navIcons_search.svg') no-repeat right 3% center;
    background-size: 16px;
    background-color: #304455;
    font-family: Open-Sans,sans-serif;
}
.side-navbar .search-box img {
    position: absolute;
    font-size: 17px;
    border: none;
    cursor: pointer;
    padding-left: 80%;
    padding-top: 2%;
}
.nav-details{
    height: 100%;
    display: flex;
    flex-direction: column;
}
.logo-details{
    height: 15%;
}
.nav-links {
    position: relative;
    overflow-y: auto;
    height: 65%;
    padding-right: 10px;
}
.nav-bar-facility-name{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.search-result-container{
    padding: 10px;
    height: 75%;
    max-height: 75%;
    overflow: auto;
    width: 100%;
    background: rgba(0, 0, 0, 0.95);
    z-index: 999;
    font-family: Open-Sans,sans-serif;
}
.no-scroll{
    display: none;
}
.search-result-title{
    display: flex;
    color: #fff;
    font-size: 14px;
    justify-content: space-between;
}
.search-result-list{
    display: flex;
    flex-wrap: wrap;
}
.search-result{
    background-color: #448CCB;
    color: white;
    font-size: 13px;
    margin: 5px;
    text-decoration: none;
    padding: 2px 5px;
    border-radius: 20px;
}
.no-menu-container{
    color: white;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.no-menu-label{
    font-size: 16px;
}
.no-menu-button{
    border: none;
    color: black;
    text-align: center;
    width: auto;
    margin-top: 10px;
    padding: 7px;
    text-decoration: none;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}
.no-menu-text{
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #fff;
    padding: 10px;
}
.sidebar-menu-item{
    display: flex;
    padding-top: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
    color: #7c7a7a;
    cursor: pointer;
}
.sidebar-menu-item-link{
    display: flex;
    text-decoration: none;
    color: #7c7a7a;
    width: 100%;
    align-items: center;
}
.sidebar-sub-menu-item-link{
    display: flex;
    text-decoration: none;
    color: #7c7a7a;
    margin-left: 20%;
}
.sidebar-sub-menu-item-link.active{
    /* color: #448CCB; */
    color: #FFFFFF;
}
.sidebar-menu-item-link.active img{
    filter: invert(20%) sepia(86%) saturate(2470%) hue-rotate(208deg) brightness(97%) contrast(104%);
}
.sidebar-menu-item-link.active{
    color: #448CCB;
    border-left: 3px solid #448CCB;
}
.sidebar-menu-item-link-icon {
    width: 25px;
    margin-left: 5px;
}

.sidebar-menu-item-link .title{
    display: flex;
    font-family: Open-Sans,sans-serif;
    padding-left: 5%;
    width: 70%;
    font-size: 16px;
}
.sidebar-sub-menu-item-link .submenu-title{
    display: flex;
    font-family: Open-Sans,sans-serif;
    font-size: 14px;
    margin: 3px 0px;
}
    
.side-navbar .nav-links .div {
    display: flex;
    flex-direction: row;
    padding-top:15px;
    padding-right: 15px;
    padding-bottom: 15px;
    /* padding: 15px; */
    /* margin-left: 2%; */
}
.sidebar-menu-item-link .nav-menu-icon{
    transform: rotate(180deg);
}
.sidebar-menu-item-link.active-menu .nav-menu-icon{
    transform: rotate(0deg);
}
.side-navbar ul > li  img {
    width: 10%;
    height: 20%;
    margin-left: 5%;
}
.side-navbar ul > li span{
    height:auto;
}
.side-navbar ul > li .title {
    /* font: normal normal 800 18px/25px Poppins; */
    display: flex;
    font-family: Poppins;
    font-weight: bolder;
    padding-left: 5%;
    font-size: 14px;
}
/* .side-navbar .nav-links div span{
    justify-content: center;
    align-items: center;
    align-self: center;
} */
 ul > li a{
      color: #7c7a7a;
}
.side-navbar ul > li > ul > li span {
    font-family: Poppins;
    font-size: 12%;
    padding-left: 13%;
}
.side-navbar ul > li span img {
    width: 15px;
    position: relative;
    margin-left:60px;
    transition: transform 0.4s;
}
.side-navbar ul ul .show {
    position: static;
    display: block;
}
.side-navbar ul > li > ul > li span {
    font-size: 12px;
}
.showing {
    display: block;
    padding-left:25px;
    margin-top:-10px;
    /* padding-top: -15%; */
    
}
.hiding {
    display: none;
}

.menu-list{
    height: 10%;
    display: none;
}

.menu-list svg{
    width: 25%;
}
.activeClass{
    color:#448CCB;
}
.submenuActiveClass{
    color: #FFFFFF;
}
.nested-menu-item{
    margin-left: 20%;
}
.nested-menu-item .sidebar-sub-menu-item-link{
    margin-left: 0px;
}
.nested-menu-item .title{
    padding-left: 0px;
}

.facility-selection-popup-container {
    width: 90%;
    z-index: 2;
    height: 50%;
    overflow: auto;
    position: absolute;
    box-sizing: border-box;
}

.facility-selection-popup {
    width: 100%;
    padding: 5px;
    user-select: none;
    border-radius: 5px;
    background-color: #fff;
}
.facility-selection-popup-item{
    padding: 5px;
    display: flex;
    color: black;
    font-size: 15px;
    cursor: pointer;
    border-radius: 5px;
    background: white;
    align-items: center;
    justify-content: space-between;
}
.facility-selection-popup-item.selected {
    border: 1px solid black;
}
.facility-selection-popup-item:hover {
    background: #e9e7e7;
}
.facility-selection-popup-logo {
    height: 50px;
    width: 50px;
}
.sub-nav-menu{
    padding: 3px 0px;
    padding-left: 20%;
    font-size: 14px;
    padding-right: 14px;
}
.sidebar-menu-item-link .submenu-title{
    padding-left: 0px;

}
.sidebar-menu-item-link .submenu-nav-title{
    padding-left: 0px;
    font-size: 14px;
}
.submenu-nav-sub-title{
    padding-left: 10%;
}
.name-arrow-nav-ctr{
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}
@media (max-width:380px) {
    .sidebar-menu-item-link-icon{
        height: 20px;
    }
}
@media screen and (max-width:600px) {
    .nav-links li span{
        margin-top:-5px;
    }
    .nav-links{
        height: 75%;
    }
}
.nav-links .vertical {
    border-left: 3px solid #448CCB;
    height: 25px;

    /* transition:height 2s; */
}
@media screen and (min-width: 1440px) {

    .nav-container,
    .side-navbar {
        width: 16%;
    }

    .side-navbar {
        /* height: 88%; */
    }

    .right-container {
        width: 84%;
    }
    .logo-details {
        height: 13%;
    }
}

@media (max-width: 1025px) {
    .side-navbar {
        /* height: 83%; */
    }
}

@media (max-width: 960px) {
    .side-navbar {
        /* height: 88%; */
    }
}

@media (max-width: 640px) {
    .side-navbar {
        /* height: 86%; */
    }
}

@media (max-width: 480px) {
    .side-navbar {
        /* height: 82%; */
    }
}
@media only screen and (max-width: 1025px) {
    .menu-list{
        display: block; 
    }
    
    .shows {
        -webkit-transition: 0.5s ease 0s;
    }
    .nav-bar-ctr{
        width: 100%;
        height: 100%;
        background-color: transparent;
        position: absolute;
        top: 0px;
        z-index: 99;
    }
    .hides {
        height: 0px;
        padding: 0;
    }
    
    .show {
        display: block;
    }
    .nav-details.show {
        display: flex;
    }
    .nav-details {
        height: 90%;

    }
    .hide {
        display: none;
    }
    .side-navbar .nav-links {
        padding-top: 0%;
    }
    .side-navbar .nav-links li {
        /* position: relative; */
        margin:5% 5% 5% 0%;
        list-style: none;
    }
    .side-navbar ul > li span{ 
         white-space: nowrap;
    }
    .side-navbar .login-details {
        padding: 5px;
    }
    .side-navbar input[type="text"] {
        padding: 10px 12px;
        font-size: 13px;
    }
    .side-navbar input[type="text"] {
        padding: 10px 15px;
        font-size: 10px;
    }
    .side-navbar .search-box img {
        display: none;
    }
    .downIcon {
        display: none;
    }
    .side-navbar .search-box img {
        display: none;
    }
    .side-navbar ul > li > ul > li span {
        font-family: Poppins;
    }
    .side-navbar{
        width: 50%;
        padding-top: 0px ;
    }
    .nav-container{
        width: 0%;
    }
    .right-container{
        width: 100%;
    }
}
@media only screen and (max-width: 768px){
    .side-navbar{
        width: 80%;
    }
    
}

/* @media screen and (min-width: 769px) and (max-width: 1024px) {
    .menu-list div {
        display: none;
    }
    .side-navbar ul > li span img{
        margin-left: 0%;
    }
    
    .side-navbar {
        width: 20%;
        background-color: black;
        position: absolute;
        top: 0px;
    }

    .side-navbar input[type="text"] {
        padding: 10px 35px;
        font-size: 10px;
    }
    .side-navbar .search-box img {
        display: none;
    }
    .side-navbar ul > li span img{
        margin-left: 50%; 
    }
    
}
@media screen and (min-width: 1025px) and (max-width: 1440px) {
    .side-navbar {
        width: 18%;
        height: inherit;
    }
    .side-navbar input[type="text"] {
        padding: 10px 40px;
        font-size: 15px;
        width: 100%;
    }
} */
